import { Typography, Link } from "@mui/material";
import React from "react";
// import Image from "src/assets/dipslogonegativ.png";
import {ReactComponent as Logo} from "src/assets/dipslogowithtext.svg"
import styled from "styled-components";

const FooterContainer = styled.div`
	background-color: #00149e;
	color: white;
	text-align: center;
	bottom: 0;
	position: absolute;
	width: 100%;
	padding-top: 30px;
	padding-bottom: 20px;
	display: grid;
	grid-template-columns: 1fr;
	grid-row-gap: 1vh;
`;

const FooterElement = styled.div`
	font-size: 1rem;
	margin: 0px 1.3rem;
`;

const LogoImage = styled.img`
	height: 6vh;
`;

const Footer = () => {
	return (
		<FooterContainer>
			<FooterElement>
				<Link href="https://www.dips.com/" target="_blank">
					<Logo />
				</Link>
			</FooterElement>
			<FooterElement>DIPS AS, Postboks 1435, 8037 Bodø • tel: +47 75 59 20 00 • open@dips.no</FooterElement>
			<FooterElement>
				Powered by{" "}
				<Link
					href="https://synthea.mitre.org/"
					sx={{
						fontWeight: "bold",
						color: "white",
						":hover": {
							color: "lightblue",
						},
					}}
					target="_blank"
				>
					Synthea
				</Link>
			</FooterElement>
		</FooterContainer>
	);
};

export default Footer;
