import SyntheaFormDto from "src/dtos/SyntheaFormDto"
import { formStateType } from "src/pages/GeneratePage";
const parseFormStateToSyntheaFormDto = (formState: formStateType) => {
    let result: SyntheaFormDto = {
        gender: formState["gender"] === "" ? undefined : formState["gender"],
        minAge: formState["minAge"] === "" ? undefined : Number(formState["minAge"]),
        maxAge: formState["maxAge"] === "" ? undefined : Number(formState["maxAge"]),
        populationSize: Number(formState["populationSize"]),
        city: formState["city"] === "" ? undefined : formState["city"],
        state: formState["state"] === "" ? undefined : formState["state"],
        disease: formState["disease"] === "" ? undefined : formState["disease"],
    };
    return result;
}


export default parseFormStateToSyntheaFormDto;

