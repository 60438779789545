import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionActions,
	AccordionDetails,
	AccordionSummary,
	Alert,
	Box,
	Button,
	FormControl,
	FormGroup,
	Grid,
	LinearProgress,
	Stack,
	Tooltip,
	Typography
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import SyntheaFormDto from "../../dtos/SyntheaFormDto";
import Age from "./Age";
import DatasetDetails from "./DatasetDetails";
import Disease from "./Disease";
import Gender from "./Gender";
import PopulationSize from "./PopulationSize";
import PresetForm from "./PresetForm";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import GeneralInformationDto from "src/dtos/GeneralInformationDto";
import GenerateDto from "src/dtos/GenerateDto";
import { formStateType } from "src/pages/GeneratePage";
import { useSocketGuidStore } from "src/stores/useSocketGuidStore";
import parseFormStateToSyntheaFormDto from "src/utils/parseFormStateToSyntheaFormDto";
import StickyContainer from "../StickyContainer";
import StateAndCity from "./StateAndCity";
import GenerateJoyrideManager from "../GenerateJoyrideManager";
import { useHasVisitedSynneStore } from "src/stores/useHasVisitedSynneStore";

export interface IComponentMap {
	radio: string;
	numberInput: string;
}

type stateNameMapType = {
	[key: string]: { display: string; public: boolean };
};

export const stateNameMap: stateNameMapType = {
	name: { display: "Dataset name", public: true },
	subscriptionKey: { display: "Subscription key", public: false },
	gender: { display: "Gender", public: true },
	populationSize: { display: "Population size", public: true },
	minAge: { display: "Minimum age", public: true },
	maxAge: { display: "Maximum age", public: true },
	state: { display: "County", public: true },
	city: { display: "City", public: true },
	disease: { display: "Condition", public: true },
};

interface IForm {
	setNewGenerations: (
		newResponse: GeneralInformationDto[],
		newDatasetName: string,
		newDatasetParameters: SyntheaFormDto | null
	) => void;
	updateKey: (value: string) => void;
	formState: formStateType;
	updateFormStates: (prop: (keyof formStateType)[], value: string[]) => void;
	hubConnection: signalR.HubConnection | null;
	subscriptionKey: string;
	clientMessage: string | null;
	generating: boolean;
	setGenerating: any;
}

const Form = (props: IForm) => {
	const [validRequiredProps, setValidRequiredProps] = useState({ name: true });
	const [showError, setShowErrors] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string[]>([]);
	const [formExpanded, setFormExpanded] = useState([true, true, true]);
	const [socketGuid] = useSocketGuidStore((state) => [state.socketGuid]);

	const updateValidRequiredProps = (prop: keyof formStateType, value: boolean) => {
		setValidRequiredProps({ ...validRequiredProps, [prop]: value });
	};

	const handleSubmit = () => {
		let errors: (keyof formStateType)[] = [];
		Object.entries(validRequiredProps).forEach(([k, v]) => {
			if (!v) {
				errors.push(k as keyof formStateType);
			}
		});
		if (errors.length !== 0) {
			let alertMessage: string[] = [];
			errors.forEach((error) => alertMessage.push(`${stateNameMap[error].display} must be set!`));
			setErrorMessage(alertMessage);
			setShowErrors(true);
		} else {
			setShowErrors(false);
			generatePatient();
			setFormExpanded(new Array(formExpanded.length).fill(false));
		}
	};

	const generatePatient = async () => {
		props.setNewGenerations([], "", null);
		if (!props.generating) {
			props.setGenerating(true);
			if (process.env.NODE_ENV === "development")
				console.log("SignalR connection id is", props.hubConnection?.connectionId);
			let inputParams: GenerateDto = {
				signalRConnectionId: props.hubConnection?.connectionId,
				syntheaForm: parseFormStateToSyntheaFormDto(props.formState),
				globalId: socketGuid,
			};

			let datasetName = props.formState.name;
			let datasetParameters = inputParams.syntheaForm;
			props.setNewGenerations([], datasetName, datasetParameters);
			try {
				let res = await axios.post("/api/Synthea", inputParams, {
					headers: {
						"Content-Type": "application/json",
					},
				});
				if (res.status === 200) {
					props.setNewGenerations(res.data, datasetName, datasetParameters);
				}
			} catch (err) {
				if (axios.isAxiosError(err)) {
					props.setGenerating(false);
					let data = JSON.parse(err.response!.data as string);
					let message = data.join("\n");
					alert(message);
				}
			} finally {
				props.setGenerating(false);
			}
		}
	};
	const theme = useTheme();
	const notSmall: boolean = useMediaQuery(theme.breakpoints.up("sm"));

	const [hasVisitedSynne, setHasVisitedSynne] = useHasVisitedSynneStore((state) => [
		state.hasVisitedSynne,
		state.setHasVisitedSynne,
	]);

	return (
		<div className="joyride-step-form">
			<Grid container spacing={2}>
				<Grid item xs={12} md={8}>
					<Stack spacing={1} direction="column">
						<Accordion
							sx={{ mx: "center", "&:before": { display: "none" } }}
							square={true}
							expanded={formExpanded[0]}
							className="joyride-step-form-patients"
						>
							<AccordionSummary
								expandIcon={
									<Tooltip title={formExpanded[0] ? "Collapse" : "Expand"}>
										<ExpandMoreIcon />
									</Tooltip>
								}
								onClick={() => {
									let newformExpanded = [...formExpanded];
									newformExpanded[0] = !newformExpanded[0];
									setFormExpanded(newformExpanded);
								}}
							>
								<Typography variant="h6">Patient details</Typography>
							</AccordionSummary>

							{/* <AccordionDetails>
								<Typography>Choose details about your patients</Typography>
							</AccordionDetails> */}
							<AccordionActions>
								<Grid container alignItems="flex-start" direction="column" marginLeft={1}>
									<FormGroup>
										<FormControl>
											<Gender selectedGender={props.formState["gender"]} updateFormStates={props.updateFormStates} />
											<PopulationSize
												updateFormStates={props.updateFormStates}
												selectedPopulationSize={props.formState["populationSize"]}
											/>
											<Age
												selectedMinAge={props.formState["minAge"]}
												selectedMaxAge={props.formState["maxAge"]}
												updateFormStates={props.updateFormStates}
											/>
											<StateAndCity
												selectedState={props.formState["state"]}
												selectedCity={props.formState["city"]}
												updateFormStates={props.updateFormStates}
											/>
										</FormControl>
									</FormGroup>
								</Grid>
							</AccordionActions>
						</Accordion>

						<Accordion
							sx={{ mx: "center", "&:before": { display: "none" } }}
							square={true}
							expanded={formExpanded[1]}
							className="joyride-step-form-conditions"
						>
							<AccordionSummary
								expandIcon={
									<Tooltip title={formExpanded[1] ? "Collapse" : "Expand"}>
										<ExpandMoreIcon />
									</Tooltip>
								}
								onClick={() => {
									let newformExpanded = [...formExpanded];
									newformExpanded[1] = !newformExpanded[1];
									setFormExpanded(newformExpanded);
								}}
							>
								<Typography variant="h6">Conditions</Typography>
							</AccordionSummary>
							{/* <AccordionDetails>
								<Typography>Choose conditions for your patients</Typography>
							</AccordionDetails> */}
							<AccordionActions>
								<Grid container alignItems="flex-start" direction="column" marginLeft={1}>
									<FormGroup sx={{ width: { sm: "100%", xs: "100%", md: "50%" } }}>
										<FormControl>
											<Disease selectedDisease={props.formState["disease"]} updateFormStates={props.updateFormStates} />
										</FormControl>
									</FormGroup>
								</Grid>
							</AccordionActions>
						</Accordion>

						<Accordion
							sx={{ mx: "center", "&:before": { display: "none" } }}
							square={true}
							expanded={formExpanded[2]}
							className="joyride-step-form-general"
						>
							<AccordionSummary
								expandIcon={
									<Tooltip title={formExpanded[2] ? "Collapse" : "Expand"}>
										<ExpandMoreIcon />
									</Tooltip>
								}
								onClick={() => {
									let newformExpanded = [...formExpanded];
									newformExpanded[2] = !newformExpanded[2];
									setFormExpanded(newformExpanded);
								}}
							>
								<Typography variant="h6">General</Typography>
							</AccordionSummary>

							{/* <AccordionDetails>
								<Typography>Dataset Details</Typography>
							</AccordionDetails> */}
							<AccordionActions>
								<Grid container alignItems="flex-start" direction="column" marginLeft={1} rowGap={3}>
									<DatasetDetails
										name={props.formState["name"]}
										showError={showError}
										updateFormStates={props.updateFormStates}
										updateValidProp={updateValidRequiredProps}
									/>
									{/* <SubscriptionKey
										selectedSubscriptionKey={props.subscriptionKey}
										propName={"subscriptionKey"}
										label="Private key"
										showError={showError}
										updateKey={props.updateKey}
										updateValidProp={updateValidRequiredProps}
									/> */}
								</Grid>
							</AccordionActions>
						</Accordion>
					</Stack>
				</Grid>

				{Object.keys(props.formState).some((e) => props.formState[e as keyof formStateType] !== undefined) && (
					<Grid item xs={12} md={4}>
						<StickyContainer isStickyAllowed={notSmall}>
							<PresetForm formState={props.formState} updateFormStates={props.updateFormStates} />
						</StickyContainer>
					</Grid>
				)}
				<Grid item xs={12}>
					{showError ? <Alert severity="error">{errorMessage.map((err: string) => err + "\n")}</Alert> : <></>}

					<Button
						className="button joyride-step-form-generate"
						sx={{ width: "100%" }}
						onClick={() => {
							handleSubmit();
							setHasVisitedSynne(true);
						}}
						disabled={props.generating}
						variant="contained"
						aria-label="Generate patients"
					>
						Generate
					</Button>

					{/* This is the component that displays loading messages */}
					{props.clientMessage !== null && props.clientMessage !== "" && props.generating && (
						<div
							style={{
								display: "grid",
								justifyContent: "center",
								textAlign: "center",
								marginTop: "4vh",
							}}
						>
							<Typography variant="h6" sx={{}}>
								{props.clientMessage}...
							</Typography>
						</div>
					)}
				</Grid>

				{props.generating && (
					<Grid item xs={12}>
						<Box sx={{ width: "100%" }}>
							<LinearProgress sx={{ height: "10px" }} />
						</Box>
					</Grid>
				)}
			</Grid>
		</div>
	);
};

export default Form;