import { Box, Grid, Stack, Typography, Skeleton } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

import SyntheaFormDto from "src/dtos/SyntheaFormDto";
import { toCamelCase } from "src/utils/stringUtils";
import { R4 } from "@ahryman40k/ts-fhir-types";

import DatasetAccordion from "src/components/DatasetAccordion";
import PageTitle from "src/components/PageTitle";
import NoDataBox from "src/components/NoDataBox";

export type dataSet = {
	id: string;
	name: string;
	formParameters: SyntheaFormDto;
	patientIds: { id: string; patientId: string }[];
};

export type FetchedPatient = {
	info: string;
	exists: boolean;
};

const PreviousGenerationsPage = () => {
	const [datasets, setDatasets] = useState<dataSet[] | null>(null);
	const [datasetLoaded, setDatasetLoaded] = useState<boolean[]>([]);
	const [patientInformation, setPatientInformation] = useState<Map<string, FetchedPatient>>(new Map());

	async function getDatasets() {
		let res = await axios.get("/api/datasets/", {
			headers: {
				"Content-Type": "application/json",
			},
		});
		let data: dataSet[] = res.data;
		data.forEach((e) => (e.formParameters = JSON.parse(e.formParameters as string)));
		data.forEach(
			(e) =>
				(e.formParameters = Object.fromEntries(Object.entries(e.formParameters).map(([k, v]) => [toCamelCase(k), v])))
		);
		let jsonObj: dataSet[] = data as dataSet[];
		return jsonObj;
	}

	useEffect(() => {
		getDatasets().then((datasets) => {
			setDatasets(datasets);
			setDatasetLoaded(new Array(datasets.length).fill(false));
		});
	}, []);

	// useEffect(() => {
	// 	datasets.forEach((d) => {
	// 		d.patientIds.forEach((p) => {
	// 			getPatient(p.patientId);
	// 		})
	// 	});
	// }, [datasets]);

	async function getPatientInfo(datasetIndex: number) {
		if (!datasetLoaded[datasetIndex]) {
			let newDatasetLoaded = [...datasetLoaded];
			if (datasets) {
				datasets[datasetIndex].patientIds.forEach((patient) => {
					getPatient(patient.patientId);
				});
				newDatasetLoaded[datasetIndex] = true;
				setDatasetLoaded(newDatasetLoaded);
			}
		}
	}

	async function getPatient(patientId: string) {
		let result: FetchedPatient = {
			info: "",
			exists: true,
		};
		try {
			let res = await axios.get(`/api/OpenDipsPatient/${patientId}`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (res.status === 200) {
				let patient: R4.IPatient = res.data;
				result.info = gatherGeneralInformation(patient);
			}
		} catch (error) {
			result.info = "deleted";
			result.exists = false;
		}
		setPatientInformation(new Map(patientInformation.set(patientId, result)));
	}

	const gatherGeneralInformation = (patient: any) => {
		// console.log(patient.name![0].given![0].value as string);
		let firstName = "";
		patient.name![0].given.forEach((name: any) => {
			firstName += name.value;
		});

		let name = firstName + " " + patient.name![0].family!.value;
		let gender = patient.gender;
		let birthDate = patient.birthDate;
		let age = calculateAge(patient.birthDate.value);

		let patientString = `${name.toString()} (Age: ${age}) ${gender.value}`;
		return patientString;
		// generalInfo["ssn"] = patient.ssn;
		// generalInfo["languages"] = patient.languages;
		// generalInfo["birthPlace"] = patient.birthPlace;
	};

	const calculateAge = (birthDate: string) => {
		let today = new Date();
		let birth = new Date(birthDate);
		let age = today.getFullYear() - birth.getFullYear();
		let monthDifference = today.getMonth() - birth.getMonth();
		if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) age--;
		return age;
	};

	//function to write the Form paramteres nicely
	// const showFormParameters = (params: string) => {
	// 	params = params.split("{")[1];
	// 	params = params.split("}")[0];
	// 	var paramsArray = params.split(/,/);
	// 	let eArray: string[][] = [];
	// 	paramsArray.forEach((e) => {
	// 		let tempArray: string[] = [];
	// 		e.split('"').forEach((i) => {
	// 			i = i.replace(":", ": ");
	// 			if (i != "") {
	// 				tempArray.push(i);
	// 			}
	// 		});
	// 		eArray.push(tempArray);
	// 	});
	// 	return eArray;
	// };
	return (
		<Stack spacing={4}>
			<PageTitle
				title="Previously generated datasets"
				description="Here you can see previously generated datasets that have been posted to Open DIPS."
			/>

			<Grid container justifyContent="center">
				<Grid item xs={12} md={12}>
					<Stack spacing={1} direction="column" justifyContent="center">
						{datasets ? (
							datasets.length > 0 ? (
								<>
									{datasets.map((d, i) => (
										<DatasetAccordion
											key={d.id}
											dataset={d}
											getPatientInfo={() => getPatientInfo(i)}
											patientInformation={patientInformation}
										/>
									))}
								</>
							) : (
								<NoDataBox text="No datasets available" />
							)
						) : (
							<>
								{new Array(5).fill(0).map((e, i) => (
									<Skeleton key={i} variant="rectangular" width="100%" height="6.5vh" />
								))}
							</>
						)}
					</Stack>
				</Grid>
			</Grid>
		</Stack>
	);
};

export default PreviousGenerationsPage;
