import { IconButton, Tooltip, TooltipProps } from "@mui/material";

interface ITooltipIconButton {
	tooltip: string;
	children: any;
	onClick?: any;
	buttonStyle?: Object;
	tooltipStyle?: Object;
	disabled?: boolean;
	placement?: TooltipProps["placement"];
	"aria-label"?: string;
}
const TooltipIconButton = (props: ITooltipIconButton) => {
	return (
		<Tooltip
			title={props.tooltip}
			style={props.tooltipStyle}
			placement={props.placement === undefined ? "bottom" : props.placement}
		>
			<span>
				<IconButton
					onClick={props.onClick}
					style={props.buttonStyle}
					disabled={props.disabled}
					aria-label={props["aria-label"]}
				>
					{props.children}
				</IconButton>
			</span>
		</Tooltip>
	);
};

export default TooltipIconButton;
