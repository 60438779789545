import create from "zustand"
import {persist} from "zustand/middleware"


interface HasVisitedSynne {
    hasVisitedSynne: boolean;
    setHasVisitedSynne: (newHasVisitedSynne: boolean) => void
}
export const useHasVisitedSynneStore = create<HasVisitedSynne>()(persist((set) => ({
    hasVisitedSynne: false,
    setHasVisitedSynne: (newHasVisitedSynne: boolean) => set(() => ({hasVisitedSynne: newHasVisitedSynne})),
}), {
    name: "synne-has-visited",
    getStorage: () => localStorage
}))