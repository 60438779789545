import * as signalR from "@microsoft/signalr";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import GeneratedData from "src/components/GeneratedData";
import GeneralInformationDto from "src/dtos/GeneralInformationDto";
import SyntheaFormDto from "src/dtos/SyntheaFormDto";
import { useSocketGuidStore } from "src/stores/useSocketGuidStore";
import { v4 as uuidv4 } from "uuid";
import Form from "../components/form/Form";
import GenerateJoyrideManager from "src/components/GenerateJoyrideManager";
import PageTitle from "src/components/PageTitle";
import { useHasVisitedSynneStore } from "src/stores/useHasVisitedSynneStore";
import Floater from "react-floater";
import NoDataBox from "src/components/NoDataBox";

export type formStateType = {
	name: string;
	subscriptionKey: string;
	gender: string;
	minAge: string;
	maxAge: string;
	populationSize: string;
	state: string;
	city: string;
	disease: string;
};

export const defaultFormState: formStateType = {
	name: "New Dataset #" + Math.floor(Math.random() * 1000),
	subscriptionKey: "",
	gender: "",
	populationSize: "1",
	minAge: "",
	maxAge: "",
	state: "",
	city: "",
	disease: "",
};

const GeneratePage = () => {
	const [datasetName, setDatasetName] = useState<string>();
	const [datasetParameters, setDatasetParameters] = useState<null | SyntheaFormDto>(null);

	const [infos, setInfos] = useState<GeneralInformationDto[]>([]);
	// const [bundles, setBundles] = useState<Object[]>([]);
	const [uuid, setUuid] = useState<string>(uuidv4());
	const [subscriptionKey, setSubscriptionKey] = useState("");

	const [generating, setGenerating] = useState(false);

	const [clientMessage, setClientMessage] = useState<string | null>(null);
	const [formState, setFormState] = useState<formStateType>(defaultFormState);

	const updateKey = (newSubscriptionKey: string) => {
		setSubscriptionKey(newSubscriptionKey);
	};

	const setNewGenerations = (
		newResponse: GeneralInformationDto[],
		newDatasetName: string,
		newDatasetParameters: SyntheaFormDto | null
	) => {
		setClientMessage("");
		setInfos(newResponse);
		setDatasetName(newDatasetName);
		setDatasetParameters(newDatasetParameters);
	};

	let hubEndpoint = process.env.NODE_ENV === "development" ? "https://localhost:7196/api/SyntheaHub" : "api/SyntheaHub";

	const [hubConnection, setHubConnection] = useState<signalR.HubConnection | null>(null);
	const [socketGuid, setSocketGuid] = useSocketGuidStore((state) => [state.socketGuid, state.setSocketGuid]);

	// Onmount
	useEffect(() => {
		let newConnection: signalR.HubConnection = new signalR.HubConnectionBuilder()
			.withUrl(hubEndpoint)
			.configureLogging(signalR.LogLevel.Information)
			.build();
		setHubConnection(newConnection);
	}, []);

	useEffect(() => {
		if (hubConnection) {
			hubConnection.on("setClientMessage", (message: string) => {
				setClientMessage(message);
			});
			hubConnection.on("setGuid", (guid: string) => {
				// This should also be set every time generate is pressed
				setSocketGuid(guid);
			});
			hubConnection.onclose(() => {
				if (process.env.NODE_ENV === "development") alert("Disconnected from hub");
			});
			hubConnection
				.start()
				.then((result) => {})
				.catch((e) => console.log("Connection failed: ", e));
		} else {
			setSocketGuid(null);
		}
	}, [hubConnection]);

	const updateFormStates = (prop: (keyof formStateType)[], value: string[]) => {
		let prevState = formState;
		let newState: formStateType = { ...prevState };

		for (let i = 0; i < prop.length; i++) {
			const element = prop[i];
			newState[element] = value[i];
		}
		setFormState(newState);
	};

	const [hasVisitedSynne, setHasVisitedSynne] = useHasVisitedSynneStore((state) => [
		state.hasVisitedSynne,
		state.setHasVisitedSynne,
	]);

	return (
		<Stack spacing={4}>
			<PageTitle
				title="Generate patients"
				titleAddition={
					<Floater
						content={
							<div onClick={() => setHasVisitedSynne(true)}>
								{
									<Typography sx={{ fontWeight: "bold" }}>
										It seems like you are new to Synne, click the question mark for a guide.
									</Typography>
								}
							</div>
						}
						offset={5}
						styles={{ container: {}, wrapper: {} }}
						placement="auto"
						open={!hasVisitedSynne}
					>
						<GenerateJoyrideManager onClickAdditional={() => setHasVisitedSynne(true)} />
					</Floater>
				}
				description="Specify details about the data you want to generate and view them here."
				wrapperClassName="joyride-intro"
			/>

			<Form
				setNewGenerations={setNewGenerations}
				subscriptionKey={subscriptionKey}
				updateKey={updateKey}
				// appendToBundles={appendToBundles}
				hubConnection={hubConnection}
				clientMessage={clientMessage}
				updateFormStates={updateFormStates}
				formState={formState}
				generating={generating}
				setGenerating={(newGenerating: boolean) => setGenerating(newGenerating)}
			/>
			{infos.length > 0 ? (
				<GeneratedData
					infos={infos}
					datasetName={datasetName}
					subscriptionKey={subscriptionKey}
					datasetParameters={datasetParameters}
					generating={generating}
				/>
			) : (
				!generating && <NoDataBox text="No generated data" />
			)}
		</Stack>
	);
};

export default GeneratePage;
