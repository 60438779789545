import { Box, Stack, Typography } from "@mui/material";

interface INoDataBox {
	text: string;
}

const NoDataBox = (props: INoDataBox) => {
	return (
		<Box sx={{ backgroundColor: "#7e7e7e22", padding: "20px" }} className="joyride-step-generated-data">
			<Stack direction="row" justifyContent="center" spacing={1}>
				<Typography variant="h4" color="text.secondary" textAlign="center">
					{props.text}
				</Typography>
			</Stack>
		</Box>
	);
};

export default NoDataBox;
