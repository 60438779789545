import { useState } from "react";
import styled from "styled-components";

interface IPatientIdContainer {
	exists: boolean;
	isCopied: boolean;
}
const PatientIdContainer = styled.div<IPatientIdContainer>`
	position: relative;
	font-family: monospace;
	font-weight: bold;
	background-color: ${(props) => (props.exists ? "lightgrey" : props.theme.palette.error.light)};
	color: ${(props) => (props.exists ? "inherit" : props.theme.palette.error.contrastText)};
	padding: 2px 8px;
	border-radius: 3px;
	text-align: center;
	transition: ease 0.3s;
	&:hover {
		filter: ${(props) => !props.isCopied && "brightness(85%)"};
		cursor: ${(props) => !props.isCopied && "pointer"};
	}
`;

interface IPatientIdCopyable {
	patientId: string;
	exists?: boolean;
}
const PatientIdCopyable = (props: IPatientIdCopyable) => {
	const [copiedBuffer, setCopiedBuffer] = useState<boolean>(false);
	const [hovering, setHovering] = useState<boolean>(false);
	const copyContent = (evt: any) => {
		evt?.stopPropagation();
		navigator.clipboard.writeText(props.patientId);
		setCopiedBuffer(true);
		setTimeout(() => {
			setCopiedBuffer(false);
		}, 2000);
	};

	return (
		<PatientIdContainer
			exists={props.exists as boolean}
			isCopied={copiedBuffer}
			onClick={copyContent}
			onMouseOver={() => setHovering(true)}
			onMouseLeave={() => setHovering(false)}
		>
			{(copiedBuffer || hovering) && (
				<span
					style={{
						position: "absolute",
						backgroundColor: "inherit",
						borderRadius: "inherit",
						width: "100%",
						padding: "2px 8px",
						left: "0px",
						top: "0px",
					}}
				>
					{copiedBuffer ? "Copied" : "Copy"}
				</span>
			)}
			{props.patientId}
		</PatientIdContainer>
	);
};

export default PatientIdCopyable;
