import React from "react";
import styled from "styled-components";
import Footer from "./Footer";
// import { Container } from "reactstrap";
import NavMenu from "./NavMenu";

const Container = styled.div`
	position: relative;
	min-height: 100vh;
`;

const Content = styled.div`
	margin: 0 8vw 0;
	padding-bottom: 200px;
`;

interface ILayout {
	children: React.ReactElement;
}
const Layout = (props: ILayout) => {

	return (
		<Container>
			<NavMenu />
			<Content>{props.children}</Content>
			<Footer />
		</Container>
	);
};

export default Layout;
