import ClearIcon from "@mui/icons-material/Clear";
import { Autocomplete, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { formStateType } from "src/pages/GeneratePage";

interface DiseaseInterface {
	selectedDisease: string;
	updateFormStates: (prop: (keyof formStateType)[], value: string[]) => void;
}

export const Disease = ({ selectedDisease, updateFormStates }: DiseaseInterface) => {
	const [disease, setDisease] = useState<{ state: string }[]>();

	const diseases = [
		"Anemia",
		"Appendicitis",
		"Breast Cancer",
		"Bronchitis",
		"Cerebral Palsy",
		"Covid 19",
		"Diabetes",
		"Epilepsy",
		"Gout",
		"Osteoarthritis",
		"Osteoporosis",
		"Pregnancy",
		"Retinopathy",
		"Sepsis",
	];


	return (
		<Stack direction="row">
			<Autocomplete
				isOptionEqualToValue={(option, value) => {
					if (option === value || value === "") {
						return true;
					}
					return false;
				}}
				disablePortal
				options={diseases}
				value={selectedDisease}
				renderInput={(params) => <TextField {...params} margin={"normal"} label="Condition" />}
				onChange={(evt: any, value: any) => {
					let curr = value;

					if (curr == null) {
						curr = "";
					}
					updateFormStates(["disease"], [curr]);
				}}
				fullWidth={true}
				autoComplete={true}
				// autoSelect={true}
				clearIcon={selectedDisease.length > 0 && <ClearIcon fontSize="small" />}
			/>
		</Stack>
	);
};

export default Disease;
