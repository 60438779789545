import {
	Card,
	CardContent,
	Grid,
	Typography,
	Button,
	TextField,
	Autocomplete,
	Stack,
	Divider,
	Box,
	CardHeader,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { stateNameMap } from "./Form";
import axios from "axios";
import PresetDto from "../../dtos/PresetDto";
import parseFormStateToPresetDto from "src/utils/parseFormStateToPresetDto";
import TooltipIconButton from "../buttons/TooltipIconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import _ from "lodash";
import styled from "styled-components";
import { LoadingButton } from "@mui/lab";
import { toCamelCase } from "src/utils/stringUtils";
import { defaultFormState, formStateType } from "src/pages/GeneratePage";

const OptionContainer = styled.div`
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
`;

type Preset = {
	name: string;
	id: string;
	preset: PresetDto | string;
};
interface IPresetForm {
	formState: any;
	updateFormStates: (prop: (keyof formStateType)[], value: string[]) => void;
}
const PresetForm = (props: IPresetForm) => {
	const [presetName, setPresetName] = useState("New preset #" + Math.floor(Math.random() * 1000));
	const [selectedPreset, setSelectedPreset] = useState<null | Preset>(null);
	const [presets, setPresets] = useState<Preset[]>([]);
	const [postingAllowed, setPostingAllowed] = useState(true);
	const [presetPosted, setPresetPosted] = useState(false);
	const [postingPreset, setPostingPreset] = useState(false);

	const postPreset = async () => {
		setPostingPreset(true);
		setPostingAllowed(false);
		let inputParams: PresetDto = parseFormStateToPresetDto(props.formState);
		try {
			let res = await axios.post("/api/Preset", JSON.stringify(inputParams), {
				params: { presetName },
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (res.status === 200) {
				// alert("preset posted");
				setPresetPosted(true);
			}
		} catch (err) {
			if (axios.isAxiosError(err)) {
				// let data = JSON.parse(err.response!.data as string);
				// let message = data.join("\n");
				// alert(message);
				console.log("Error posting");
			}
		}
		setPostingPreset(false);
		await getPresets();
	};

	const deletePreset = async (presetIdToDelete: string) => {
		try {
			let res = await axios.delete("/api/Preset", { params: { presetId: presetIdToDelete } });
			if (res.status == 200) {
				let currPresets = presets;
				setPresets(currPresets.filter((e: any) => e.id !== presetIdToDelete));
			}
		} catch (err) {
			if (axios.isAxiosError(err)) {
				console.log("Error deleting");
			}
		}
	};

	const getPresets = async () => {
		try {
			let res = await axios.get("/api/Preset");
			if (res.status == 200) {
				let objects: Preset[] = res.data;
				objects.forEach((e: Preset) => (e.preset = JSON.parse(e.preset as string)));
				// Transforms the Preset object so that keys are camelCase and values are set to strings (null values become empty strings)
				// ex: MaxAge: null -> maxAge: ""
				objects.forEach(
					(e: any) =>
						(e.preset = Object.fromEntries(
							Object.entries(e.preset).map(([k, v]) => [toCamelCase(k), v == null ? "" : `${v}`])
						))
				);
				setPresets(objects);
			}
		} catch (err) {
			if (axios.isAxiosError(err)) {
				console.log("Error getting");
			}
		}
	};

	const handlePresetSelectChange = (evt: any, value: any) => {
		if (value == null) {
			setPostingAllowed(true);
			setSelectedPreset(null);
			let { name, subscriptionKey, ...restDefault } = defaultFormState;
			let defaultFormStateKeys: (keyof formStateType)[] = Object.keys(restDefault) as (keyof formStateType)[];
			props.updateFormStates(
				defaultFormStateKeys,
				defaultFormStateKeys.map((e: keyof formStateType) => defaultFormState[e])
			);
			return 0;
		}
		let validPresets = presets.filter((e: any) => e.id == value.id);
		if (validPresets.length == 0) {
			setSelectedPreset(null);
			setPostingAllowed(true);
			return 0;
		}
		let validPreset: any = validPresets[0];
		setSelectedPreset(value);
		let keys: (keyof formStateType)[] = Object.keys(validPreset.preset) as (keyof formStateType)[];
		let values = keys.map((e: string) => validPreset.preset[e]);
		setPostingAllowed(false);
		props.updateFormStates(keys, values);
		setPresetPosted(false);
	};

	useEffect(() => {
		getPresets();
	}, []);

	useEffect(() => {
		//Find matching preset (buggy atm)
		// let validPresets = presets.filter((e: any) => {
		// 	const { name, ...formState } = props.formState;
		// 	return _.isEqual(e.Preset, formState);
		// });
		// if (validPresets.length > 0) {
		// 	let selectedPreset: any = validPresets[0];
		// 	setSelectedPreset({ name: selectedPreset.Name, id: selectedPreset.Id });
		// }
		const { name, subscriptionKey, ...formState } = props.formState;
		if (!presets.some((e: any) => _.isEqual(formState, e.preset))) {
			setSelectedPreset(null);
			setPostingAllowed(true);
		}
		if (selectedPreset == null) {
			setPostingAllowed(true);
		}
		setPresetPosted(false);
	}, [props.formState]);
	let autoCompleteOptions = presets.map((e: any) => {
		return { name: e.name, id: e.id };
	});
	return (
		<Card square={true} className="joyride-step-preset-menu">
			<CardContent>
				<Typography gutterBottom variant="h6" component="div">
					Preset menu
				</Typography>
				<Stack spacing={2}>
					<Autocomplete
						options={autoCompleteOptions}
						renderInput={(params) => <TextField {...params} label="Select a preset" />}
						getOptionLabel={(option: any) => option.name}
						isOptionEqualToValue={(o: any, v: any) => o.id === v.id}
						renderOption={(props, option) => (
							<Grid component="li" direction="row" sx={{ width: "100%" }} {...props} container>
								<OptionContainer>
									<Typography>{option.name}</Typography>
									<TooltipIconButton
										tooltip="Delete preset"
										onClick={(evt: any) => {
											deletePreset(option.id);
											evt?.stopPropagation();
										}}
										placement="right"
									>
										<DeleteIcon color="warning" />
									</TooltipIconButton>
								</OptionContainer>
							</Grid>
						)}
						onChange={handlePresetSelectChange}
						value={selectedPreset}
						className="joyride-step-preset-menu-autocomplete"
						noOptionsText="No presets available"
					/>

					<div>
						You have selected the following parameters:
						{Object.entries(stateNameMap).map(([k, v]) => {
							if (props.formState[k] != "" && v.public) {
								return (
									<Typography
										key={k}
										sx={{ fontSize: 14, mx: "auto" }} //"&:hover": { textDecoration: "line-through", cursor: "pointer" }
										color="text.secondary"
										gutterBottom
										// onClick={() => props.updateFormStates([k], [defaultFormState[k]])}
									>
										{`${v.display}: ${props.formState[k]}`}
									</Typography>
								);
							}
						})}
					</div>
					<Stack
						direction={{ xs: "column", sm: "row", md: "column", lg: "row" }}
						spacing={2}
						justifyContent="space-between"
					>
						<TextField
							value={presetName}
							onChange={(e: any) => setPresetName(e.target.value)}
							label="Preset name"
							disabled={!postingAllowed}
							sx={{ width: "100%" }}
							inputProps={{ "aria-label": "Preset name" }}
							required
						></TextField>
						<LoadingButton
							onClick={postPreset}
							disabled={!postingAllowed || presetName.length == 0}
							loading={postingPreset}
							variant="contained"
							color={presetPosted ? "success" : "primary"}
							sx={{ width: { sm: "30%", md: "100%", lg: "60%" } }}
							aria-label="Save preset"
						>
							{presetPosted ? <CheckIcon /> : "Save"}
						</LoadingButton>
					</Stack>
				</Stack>
			</CardContent>
		</Card>
	);
};

export default PresetForm;
