import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

type AuthContextType = {
	authenticated: boolean;
	onLogin: (suspendedRoute: string) => Promise<void>;
	onLogout: () => void;
};

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
	return useContext(AuthContext);
};

interface IAuthProvider {
	children: JSX.Element;
}

const AuthProvider = (props: IAuthProvider) => {
	const [authenticated, setAuthenticated] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		checkLogin();
	}, []);

	const checkLogin = async () => {
		const auth = await axios.get("/api/Authentication");
		setAuthenticated(auth.data);
		const suspendedRoute = window.localStorage.getItem("synne-suspended-route");
		if (auth.data && suspendedRoute) {
			navigate(suspendedRoute);
		}
		window.localStorage.removeItem("synne-suspended-route");
	};

	// TODO
	const handleLogin = async (suspendedRoute: string | null) => {
		if (suspendedRoute) {
			window.localStorage.setItem("synne-suspended-route", suspendedRoute);
		}

		window.location.href = "/Account/Login";
		// const auth = await axios.post("/api/Authentication");
		// setAuthenticated(true);
	};

	// TODO: remove Cookies...
	const handleLogout = async () => {
		window.localStorage.removeItem("synne-suspended-route");
		let response = await axios.delete("/api/Authentication");
		if (response.status === 200) {
			setAuthenticated(false);
		} else {
			throw "Failed to logout ";
		}
	};

	const value = {
		authenticated,
		onLogin: handleLogin,
		onLogout: handleLogout,
	};

	return <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>;
};

export default AuthProvider;
