import styled from "styled-components";
import StickyContainer from "src/components/StickyContainer";
import GeneralInformationDto from "src/dtos/GeneralInformationDto";
import BundlesMenu from "src/components/view_patients/BundlesMenu";
import ViewBundle from "../components/view_patients/ViewBundle";
import Alert, { AlertColor } from "@mui/material/Alert";
import { useEffect, useState, useRef } from "react";
import PostAlertResponseDto, { getHighestAlert } from "src/dtos/PostErrorDto";
import PostBundlesDto from "src/dtos/PostBundlesDto";
import { useSocketGuidStore } from "src/stores/useSocketGuidStore";
import SyntheaFormDto from "src/dtos/SyntheaFormDto";
import axios, { AxiosResponse } from "axios";

const Container = styled.div`
	margin-top: 2vh;
	display: grid;
	grid-auto-flow: row;
	grid-template-columns: 100%;
	grid-row-gap: 2vh;
`;

const BundlesContainer = styled.div``;

interface IGeneratedData {
	infos: GeneralInformationDto[];
	datasetName: string | undefined;
	subscriptionKey: string;
	datasetParameters: null | SyntheaFormDto;
	generating: boolean;
}
const GeneratedData = (props: IGeneratedData) => {
	const [postedAllBundles, setPostedAllBundles] = useState<boolean>(false);
	const [postedBundles, setPostedBundles] = useState<boolean[]>([]);
	const [postedPatientIds, setPostedPatientIds] = useState<(string | null)[]>([]);
	const [postingAllBundles, setPostingAllBundles] = useState<boolean>(false); // For feedback when button to post all is pressed
	const [postingBundles, setPostingBundles] = useState<boolean[]>([]); // For feedback when posting individual bundles
	const [displayedAlert, setDisplayedAlert] = useState<{ type: AlertColor; text?: string } | null>(null);
	const [bundleAlerts, setBundleAlerts] = useState<{ errorType: string; errorText: string }[][]>([]);
	const [socketGuid, setSocketGuid] = useSocketGuidStore((state) => [state.socketGuid, state.setSocketGuid]);
	const alertDivRef = useRef<null | HTMLDivElement>(null);

	useEffect(() => {
		setPostedAllBundles(false);
		setPostedBundles(new Array(props.infos.length).fill(false));
		setPostedPatientIds(new Array(props.infos.length).fill(null));
		setPostingBundles(new Array(props.infos.length).fill(false));
		setPostingAllBundles(false);
		setDisplayedAlert(null);
		setBundleAlerts([]);
	}, [props.infos]);

	const handleAlerts = (errorDto: PostAlertResponseDto, indexMap: number[]) => {
		let alerts: { errorType: string; errorText: string }[][] = [];
		for (let i = 0; i < props.infos.length; i++) {
			alerts.push([]);
		}

		let curIndex: number;
		let levels: (keyof PostAlertResponseDto)[] = ["errors", "warnings", "infos", "success"];
		let levelName = ["error", "warning", "info", "success"];
		for (let i = 0; i < levels.length; i++) {
			for (let j = 0; j < errorDto[levels[i]].length; j++) {
				const error = errorDto[levels[i]][j];
				if (error.bundleIndex !== undefined) {
					if (error.bundleIndex !== null) {
						curIndex = indexMap[error.bundleIndex];
						alerts[curIndex].push({ errorType: levelName[i], errorText: error.errorText });
					}
				}
			}
		}
		setBundleAlerts(alerts);
	};
	async function postBundles(indices: number[] = []) {
		let postedAll = false; // Indicating if this call to the method was meant to post all (remaining) bundles
		if (indices.length === 0) {
			props.infos.forEach((_, i) => {
				if (!postedBundles[i]) {
					indices.push(i);
				}
			});
			postedAll = true; // All will be posted
			setPostingAllBundles(true);
		} else {
			// If certain bundles will be posted
			let newPostingBundles: boolean[] = [...postingBundles];
			indices.forEach((bundleIndex, i) => {
				newPostingBundles[bundleIndex] = true;
			});
			setPostingBundles(newPostingBundles);
		}
		let requestBody: PostBundlesDto = {
			// subscriptionKey: props.subscriptionKey,
			parametersUsed: props.datasetParameters,
		};
		try {
			let responses: AxiosResponse<any, any>[] = [];
			if (postedBundles.every((posted: boolean) => !posted) && postedAll) {
				// Request server to post all if none are posted and "post all"-button was clicked
				responses.push(
					await axios.post(`/api/InternalFhirAPI/Indices/${socketGuid}`, JSON.stringify(requestBody), {
						params: { datasetName: props.datasetName },
						headers: {
							"Content-Type": "application/json",
						},
					})
				);
				setPostedPatientIds(responses[0].data.patientIds); // Index first response as it is the only one
			} else {
				let newPostedPatientIds = [...postedPatientIds];
				for (let i = 0; i < indices.length; i++) {
					const bundleIndex = indices[i];
					let res = await axios.post(
						`/api/InternalFhirAPI/Indices/${socketGuid}/${bundleIndex}`,
						JSON.stringify(requestBody),
						{
							params: { datasetName: props.datasetName },
							headers: {
								"Content-Type": "application/json",
							},
						}
					);
					newPostedPatientIds[bundleIndex] = res.data.patientIds[0];
					responses.push(res);
				}
				console.log(postedPatientIds);
				
				setPostedPatientIds(newPostedPatientIds);
			}
			if (process.env.NODE_ENV === "development") console.log(responses);
			let alerts: PostAlertResponseDto = {
				errors: responses.map((e) => e.data.alerts.errors).flat(),
				warnings: responses.map((e) => e.data.alerts.warnings).flat(),
				infos: responses.map((e) => e.data.alerts.infos).flat(),
				success: responses.map((e) => e.data.alerts.success).flat(),
			};

			if (responses.every((res) => res.status === 200)) {
				let highestAlert = getHighestAlert(alerts);
				if (highestAlert) {
					handleAlerts(alerts, indices);
					setDisplayedAlert({ type: highestAlert.errorType as AlertColor, text: highestAlert.errorText });
				} else {
					setDisplayedAlert({ type: "success", text: "Patient(s) Posted" });
				}

				if (postedAll) {
					setPostedAllBundles(true);
					setPostedBundles(new Array(props.infos.length).fill(true));
					setPostingAllBundles(false);
				} else {
					let newPostedBundles: boolean[] = [...postedBundles];
					let newPostingBundles: boolean[] = [...postingBundles];
					indices.forEach((bundleIndex, i) => {
						newPostedBundles[bundleIndex] = true;
						newPostingBundles[bundleIndex] = false;
					});

					let allWerePostedInTheEnd = true; // If the user has individually posted each bundle
					newPostedBundles.forEach((posted) => {
						if (!posted) {
							allWerePostedInTheEnd = false;
							return;
						}
					});

					if (allWerePostedInTheEnd) {
						setPostedAllBundles(true);
					}
					setPostedBundles(newPostedBundles);
					setPostingBundles(newPostingBundles);
				}
			}
		} catch (error: any) {
			if (postedAll) setPostingAllBundles(false);
			else setPostingBundles(postingBundles.map((e: boolean) => false));

			let errorDto: PostAlertResponseDto = error.response.data.alerts;
			let highestAlert = getHighestAlert(errorDto);
			if (highestAlert != null) {
				handleAlerts(errorDto, indices);
				setDisplayedAlert({ type: highestAlert.errorType as AlertColor, text: highestAlert.errorText });
			} else {
				setDisplayedAlert(null);
			}
		} finally {
			alertDivRef.current!.scrollIntoView({ behavior: "auto", block: "center" });
		}
	}

	const handleDownload = async (evt: any, index: number = -1) => {
		evt?.stopPropagation();
		try {
			let endPoint = `/api/Synthea/Bundles/${socketGuid}`;
			let fileName =
				index == -1 ? `${props.datasetName?.replaceAll(" ", "_")}` : `${props.infos[index].name.replaceAll(" ", "_")}`; // Download all
			if (index != -1) endPoint += `/${index}`; // Download single
			let res = await axios.get(endPoint);
			if (res.status == 200) {
				let downloadAnchor = document.createElement("a");
				let file = "data:text/json;charset=utf-8," + encodeURIComponent(res.data);
				downloadAnchor.setAttribute("href", file);
				downloadAnchor.setAttribute("download", fileName + ".json");
				document.body.appendChild(downloadAnchor);
				downloadAnchor.click();
				downloadAnchor.remove();
			}
		} catch (err) {
			if (axios.isAxiosError(err)) {
				console.log("Error getting");
			}
		}
	};

	return (
		<Container className="joyride-step-generated-data">
			<StickyContainer isStickyAllowed={true} stickyStyle={{ boxShadow: "1px -1px 31px 0px rgba(0, 0, 0, 0.32)" }}>
				<BundlesMenu
					downloadBundles={handleDownload}
					postBundles={postBundles}
					postedBundles={postedAllBundles}
					postingBundles={postingBundles}
					postingAllBundles={postingAllBundles}
				/>
			</StickyContainer>
			<div ref={alertDivRef}>
				{displayedAlert && (
					<Alert severity={displayedAlert.type}>{displayedAlert.text ? displayedAlert.text : "Posted Patients"}</Alert>
				)}
			</div>
			<BundlesContainer>
				{props.infos.map((item, i) => (
					<ViewBundle
						info={item}
						index={i}
						key={i}
						postBundles={postBundles}
						posted={postedBundles[i]}
						posting={postingBundles[i]}
						postingAll={postingAllBundles}
						alerts={bundleAlerts[i]}
						disabled={props.generating}
						handleDownload={handleDownload}
						patientId={postedPatientIds[i]}
					/>
				))}
			</BundlesContainer>
		</Container>
	);
};

export default GeneratedData;
