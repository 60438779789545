import ManIcon from "@mui/icons-material/Man";
import WomanIcon from "@mui/icons-material/Woman";

interface IGenderIcon {
	gender: string;
	style?: Object;
}
const GenderIcon = (props: IGenderIcon) => {
	let gender = props.gender.toLowerCase().charAt(0) === "f" ? "f" : "m";
	return gender === "f" ? <WomanIcon style={props.style} /> : <ManIcon style={props.style} />;
};

export default GenderIcon;
