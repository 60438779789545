import { useEffect, useRef, useState } from "react";
import CSS from "csstype";
import styled from "styled-components";
interface IContainer {
	stickyStyle: Object;
}
const Container = styled.div<IContainer>((props: any) => ({
	width: "100%",
	position: "sticky",
	top: "20px",
	zIndex: "100",
	transition: "0.2s ease",
	"&.isSticky": {
		...props.stickyStyle,
	},
}));

interface IStickyContainer {
	children: any;
	isStickyAllowed?: boolean;
	stickyStyle?: CSS.Properties;
}

// Makes the children stick to the top of the part of the outer container that is visible on the screen
const StickyContainer = (props: IStickyContainer) => {
	const [isSticky, setIsSticky] = useState(false);
	const ref = useRef(null);
	const intersectionHandler = (e: any) => {
		setIsSticky(!e[0].isIntersecting);
	};

	useEffect(() => {
		const { current } = ref;
		const observer = new IntersectionObserver((e: any) => intersectionHandler(e), {
			root: null,
			rootMargin: "-21px 0px 0px 0px",
			threshold: [1],
		});
		if (ref.current) observer.observe(ref.current);
		return () => {
			observer.unobserve(current!);
		};
	}, []);

	return (
		<Container
			ref={ref}
			className={isSticky && props.isStickyAllowed ? "isSticky" : ""}
			stickyStyle={props.stickyStyle == null ? {} : props.stickyStyle}
		>
			{props.children}
		</Container>
	);
};

export default StickyContainer;
