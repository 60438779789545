import create from "zustand"


interface ISocketGuidStore {
    socketGuid: string | null;
    setSocketGuid: (newGuid: string | null) => void;

}

export const useSocketGuidStore = create<ISocketGuidStore>((set) => ({
    socketGuid: null,
    setSocketGuid: (newGuid: string | null) => set(() => ({socketGuid: newGuid})),
}))