import { Stack, TextField } from "@mui/material";
import { useState } from "react";
import { formStateType } from "src/pages/GeneratePage";

interface AgeInterface {
	selectedMinAge: string;
	selectedMaxAge: string;
	updateFormStates: (prop: (keyof formStateType)[], value: string[]) => void;
}

const MAXAGE = 110;
const MINAGE = 0;

export const Age = ({ selectedMinAge, selectedMaxAge, updateFormStates }: AgeInterface) => {
	const [isError, setIsError] = useState(false);
	return (
		<Stack direction="row">
			<TextField
				label="Minimum age"
				onChange={(e) => {
					if (e.target.value === "") {
						setIsError(false);
						updateFormStates(["minAge"], [e.target.value]);
						return;
					}

					let newValue = Number(e.target.value);

					if (!isNaN(newValue) && MINAGE <= newValue && MAXAGE >= newValue && (newValue >= 1 || newValue <= 0)) {
						const withoutLeading0 = +newValue;
						updateFormStates(["minAge"], [withoutLeading0.toString()]);
						selectedMaxAge && setIsError(newValue > Number(selectedMaxAge));
					}
				}}
				error={isError}
				margin="normal"
				value={selectedMinAge}
				helperText={isError ? "Minimum age must be smaller than maximum age" : ""}
				inputProps={{ "aria-label": "Minimum age" }}
			/>
			<TextField
				label="Maximum age"
				onChange={(e) => {
					if (e.target.value === "") {
						setIsError(false);
						updateFormStates(["maxAge"], [e.target.value]);
						return;
					}

					let newValue = Number(e.target.value);

					if (!isNaN(newValue) && MINAGE <= newValue && MAXAGE >= newValue && (newValue >= 1 || newValue <= 0)) {
						const withoutLeading0 = +newValue;
						updateFormStates(["maxAge"], [withoutLeading0.toString()]);
						selectedMinAge && setIsError(newValue < Number(selectedMinAge));
					}
				}}
				error={isError}
				margin="normal"
				value={selectedMaxAge}
				inputProps={{ "aria-label": "Maximum age" }}
			/>
		</Stack>
	);
};

export default Age;
