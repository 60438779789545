import 'bootstrap/dist/css/bootstrap.css';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/system';
import { createTheme } from '@mui/material';
import { ThemeProvider as SCThemeProvider } from "styled-components";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

const mainTheme = createTheme({
	// palette: {
	// 	success: { main: "#6CBC70" },
	// 	info: { main: "#34C5B7" },
	// 	warning: { main: "#F0664F" },
	// 	error: { main: "#db452c" },
	// },
	typography: {
		allVariants: {
			fontFamily: "CircularXXWeb-Regular",
		},
	},
	components: {
		MuiButton: {
			variants: [
				{
					props: {},
					style: {
						borderRadius: "100px",

						fontWeight: "bold",
					},
				},
				{
					props: { variant: "contained", color: "primary" },
					style: {
						backgroundColor: "#00149E",

						"&:hover": {
							backgroundColor: "#050948",
						},
						// Some CSS
					},
				},
				{
					props: { variant: "contained", color: "secondary" },
					style: {
						backgroundColor: "#A8F0E9",
						color: "#262626",

						"&:hover": {
							backgroundColor: "#34C5B7",
						},
						// Some CSS
					},
				},
				{
					props: { variant: "outlined", color: "secondary" },
					style: {
						border: "2px solid",
						color: "white",

						"&:hover": {
							backgroundColor: "#A8F0E9",
							border: "2px solid #A8F0E9",
							color: "#00149E",
						},
						// Some CSS
					},
				},
			],
			// styleOverrides: {
			//   // Name of the slot
			//   root: {
			//     backgroundColor: "#00149E",
			//     textTransform: "none",
			//     // Some CSS
			//   },
			// },
		},
		MuiAlert: {
			// styleOverrides: {
			// 	standardSuccess: {
			// 		backgroundColor: "#E7F9E1",
			// 		color: "black",
			// 	},
			// 	standardInfo: {
			// 		backgroundColor: "#E1F9F6",
			// 		color: "black",
			// 	},
			// 	standardWarning: {
			// 		backgroundColor: "#F9EAE1",
			// 		color: "black",
			// 	},
			// 	standardError: {
			// 		backgroundColor: "#F9EAE1",
			// 		color: "black",
			// 	},
			// },
		},
	},
});

root.render(
	<BrowserRouter basename={baseUrl!}>
		<ThemeProvider theme={mainTheme}>
			<SCThemeProvider theme={mainTheme}>
				<App />
			</SCThemeProvider>
		</ThemeProvider>
	</BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
