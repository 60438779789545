import { useRef, useState } from "react";
import Joyride, { Step, CallBackProps, TooltipRenderProps, StoreHelpers } from "react-joyride";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TooltipIconButton from "./buttons/TooltipIconButton";
import styled from "styled-components";
import { Box, Tooltip } from "@mui/material";

interface IGenerateJoyrideManager {
	onClickAdditional?: () => void;
}
const GenerateJoyrideManager = (props: IGenerateJoyrideManager) => {
	const [steps, setSteps] = useState<Step[]>([
		{
			target: ".joyride-intro",
			content:
				"You are now at the page where you will be generating data. Follow the steps in this guide to learn how.",
			disableBeacon: true,
			placement: "auto",
		},
		{
			target: ".joyride-step-form",
			// title: "Hello",
			content: "Interact with this area to specify the characteristics of your synthetic patients.",
			disableBeacon: true,
			placement: "auto",
		},
		{
			target: ".joyride-step-form-patients",
			content: "Choose specific details about your patients.",
			disableBeacon: true,
		},
		{
			target: ".joyride-step-form-conditions",
			content: "Specify a condition you want your patients to have.",
			disableBeacon: true,
		},
		{
			target: ".joyride-step-form-general",
			content: "Specify general details about your data.",
			disableBeacon: true,
		},
		{
			target: ".joyride-step-preset-menu",
			content:
				"The parameters you specify will appear here, and can be saved as a preset. Give it a name and click save.",
			disableBeacon: true,
		},
		{
			target: ".joyride-step-preset-menu-autocomplete",
			content: "All your saved presets are accessible through this menu. ",
			disableBeacon: true,
		},
		{
			target: ".joyride-step-form-generate",
			content: "When you are satisfied with your parameters, click this button to generate the patients.",
			disableBeacon: true,
		},
		{
			target: ".joyride-step-generated-data",
			content: "The data you have generated will appear in this area.",
			disableBeacon: true,
		},
	]);

	const [active, setActive] = useState(false);

	const handleCallback = (data: CallBackProps) => {
		if (data.status === "finished" || data.status === "skipped" || data.action === "close") {
			setActive(false);
		}
	};

	return (
		<Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
			<Tooltip title="View Tour">
				<HelpOutlineIcon
					sx={{ ":hover": { cursor: "pointer" }, fontSize: "40px" }}
					onClick={() => {
						setActive(true);
						props.onClickAdditional !== undefined && props.onClickAdditional();
					}}
				/>
			</Tooltip>

			{/* <p>{String(active)} </p> */}
			{active && (
				<Joyride
					steps={steps}
					callback={handleCallback}
					continuous
					showSkipButton
					showProgress
					// disableOverlayClose={true}
					styles={{
						options: {
							primaryColor: "#00149E",
						},
					}}
				/>
			)}
		</Box>
	);
};

export default GenerateJoyrideManager;
