import {
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup
} from "@mui/material";
import { formStateType } from "src/pages/GeneratePage";

interface GenderInterface {
	selectedGender: string | undefined;
	updateFormStates: (prop: (keyof formStateType)[], value: string[]) => void;
}

export const Gender = ({ selectedGender, updateFormStates }: GenderInterface) => {
	const handleClickGender = (e: any) => {
		if (e.target.value === selectedGender) {
			updateFormStates(["gender"], [""]);
		} else {
			updateFormStates(["gender"], [e.target.value]);
		}
	};

	return (
		<>
			<FormLabel>Gender</FormLabel>
			<RadioGroup value={selectedGender ? selectedGender : ""} row={true} aria-label="Gender">
				<FormControlLabel
					value="F"
					control={<Radio onClick={handleClickGender} inputProps={{ "aria-label": "Female" }} />}
					label="Female"
				/>
				<FormControlLabel
					value="M"
					control={<Radio onClick={handleClickGender} inputProps={{ "aria-label": "Male" }} />}
					label="Male"
				/>
			</RadioGroup>
		</>
	);
};

export default Gender;
