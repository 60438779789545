import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Card,
	CardContent,
	Paper,
	Skeleton,
	Stack,
	TableContainer,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	useMediaQuery,
	useTheme,
	Tooltip,
	Divider,
} from "@mui/material";
import { withTheme } from "styled-components";
import { dataSet, FetchedPatient } from "src/pages/PreviousGenerationsPage";
import { stateNameMap } from "src/components/form/Form";
import CSS from "csstype";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { useState } from "react";
import PatientIdCopyable from "./PatientIdCopyable";

interface IDatasetAccordion {
	getPatientInfo: () => void;
	dataset: dataSet;
	patientInformation: Map<string, FetchedPatient>;
}
const DatasetAccordion = (props: IDatasetAccordion) => {
	return (
		<Accordion square={false} onClick={props.getPatientInfo}>
			<AccordionSummary expandIcon={<ExpandMoreIcon />}>
				<Typography variant="h6">{props.dataset.name}</Typography>
			</AccordionSummary>
			<AccordionDetails>
				{/* <Stack divider={<Divider orientation="horizontal" sx={{ height: "100%" }} />}>
					<div>Hei</div>
					<div>hallo</div>
					<div>Heisann</div>
				</Stack> */}
				<Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
					<Box>
						<Typography variant="h5">Patients</Typography>
						<TableContainer component={Box}>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>
											<Typography sx={{ fontWeight: "bold" }}>ID</Typography>
										</TableCell>
										<TableCell align="right">
											<Typography sx={{ fontWeight: "bold" }}>Info</Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{props.dataset.patientIds.map((dbPatient, idx) => {
										let fetchedPatient: FetchedPatient | undefined = props.patientInformation.get(dbPatient.patientId);
										return (
											<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }} key={dbPatient.id}>
												{fetchedPatient ? (
													<>
														<TableCell>
															<PatientIdCopyable patientId={dbPatient.patientId} exists={fetchedPatient?.exists} />
														</TableCell>
														<TableCell align="right">{fetchedPatient.info}</TableCell>
													</>
												) : (
													<TableCell colSpan={2}>
														<Skeleton width="30vw" />
													</TableCell>
												)}
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
					<Divider orientation="vertical" />
					<Box>
						{/* <Card>
							<CardContent> */}
						<Typography variant="h5">Parameters used</Typography>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>
										<Typography sx={{ fontWeight: "bold" }}>Parameter</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography sx={{ fontWeight: "bold" }}>Value</Typography>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Object.entries(props.dataset.formParameters).map(
									([k, v]) =>
										v !== null && (
											<TableRow key={k}>
												<TableCell>{stateNameMap[k].display}</TableCell>
												<TableCell align="right">{v}</TableCell>
											</TableRow>
										)
								)}
							</TableBody>
						</Table>
						{/* </CardContent>
						</Card> */}
					</Box>
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};

export default DatasetAccordion;
