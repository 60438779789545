import HomePage from "./pages/HomePage";
import GeneratePage from "./pages/GeneratePage";
import PreviousGenerationsPage from "./pages/PreviousGenerationsPage";
import GettingStartedPage from "./pages/GettingStartedPage";

interface IAppRoute {
	index?: boolean;
	path?: string;
	element: any;
	requiresAuth: boolean;
}

const AppRoutes: IAppRoute[] = [
	{
		index: true,
		element: <HomePage />,
		requiresAuth: false,
	},
	{
		path: "/generate",
		element: <GeneratePage />,
		requiresAuth: true,
	},
	{
		path: "/previous",
		element: <PreviousGenerationsPage />,
		requiresAuth: true,
	},
	{
		path: "getting-started",
		element: <GettingStartedPage />,
		requiresAuth: false,
	},
];
export default AppRoutes;
