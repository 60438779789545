import { Stack, Typography } from "@mui/material";
import React from "react";

interface IPageTitle {
	title: string;
	description?: string;
	titleAddition?: any;
	wrapperClassName?: string;
}

const PageTitle = (props: IPageTitle) => {
	return (
		<Stack
			sx={{ backgroundColor: "#00149e", padding: "2rem", paddingBottom: "1.5rem", color: "white" }}
			className={props.wrapperClassName !== null ? props.wrapperClassName : ""}
		>
			<Stack direction="row" justifyContent="space-between" sx={{ marginBottom: "0.35em" }}>
				<Typography variant="h4" color="white">
					{props.title}
				</Typography>
				{props.titleAddition}
			</Stack>
			{props.description !== null && <Typography variant="subtitle1">{props.description}</Typography>}
		</Stack>
	);
};

export default PageTitle;
