import { Box, Button, Grid, Link, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useAuth } from "../AuthProvider";
import FrontPageImage from "src/assets/frontpageimage.jpg";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import CloudOutlinedIcon from "@mui/icons-material/CloudOutlined";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import CSS from "csstype";
import { useEffect, useState, useRef, useCallback } from "react";

const BOX_SHADOW_COLOR = "#00000047";
const Container = styled.div`
	display: grid;
	grid-auto-flow: rows;
	grid-row-gap: 8vh;
	/* justify-content: center; */
`;

interface IOverlappingContainer {
	notSmall: boolean;
}
const OverlappingContainer = styled.div<IOverlappingContainer>`
	display: grid;
	grid-template-columns: ${(props) => (props.notSmall ? "3fr 3fr 3fr" : "1fr")};
	box-shadow: 10px 10px 0px 0px ${BOX_SHADOW_COLOR};
`;

const WelcomeContainer = styled.div`
	padding: 30px;
	background-color: #00149e;
	color: white;

	display: grid;
	grid-row-gap: 1vh;
`;

const WelcomeText = styled.p`
	/* text-align: justify; */
	font-weight: bold;
	font-size: 20px;
	/* margin: auto; */
`;

const Menu = styled.div`
	display: grid;
	/* grid-template-columns: 1fr 1fr; */
	grid-auto-flow: column;
	grid-column-gap: 2vw;
`;
interface IFragmentContainer {
	columns: number;
}
const FragmentContainer = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-column: 2;
	grid-row: 1;
	z-index: 1;
	/* background-color: #cc0f0fb7; */
`;

interface IFragment {
	units: number;
	delay: number;
}

const anim = (units: number) => keyframes`
	from{
		width: 0%;
	} 
	to{
		width: ${units}%;
	}
`;

const Fragment = styled.div<IFragment>`
	z-index: 1;
	background-color: #00149e;
	width: 0;
	/* transition: 0.3s ease; */
	animation: ${(props) => anim(props.units)} 0.2s ease;
	animation-delay: ${(props) => props.delay}ms;
	animation-fill-mode: forwards;

	/* width: 20px; */
	/* :after {
		content: "";
		display: block;
		padding-bottom: 100%;
	} */
`;

// Fragment.defaultProps = {
// 	visible: true,
// };

const ImageContainer = styled.div`
	grid-column: 2 / span all;
	grid-row: 1;
	background-image: url(${FrontPageImage});
	background-position: center;
	background-size: cover;
`;

const InfoBox = styled.div`
	display: grid;
	grid-auto-flow: row;

	color: white;
	background-color: #050948;
	padding: 30px;
	grid-gap: 1vh;
	height: 100%;
	box-shadow: 10px 10px 0px 0px ${BOX_SHADOW_COLOR};
	align-content: flex-start;

	transition: ease 0.1s;
	:hover {
		/* transform: rotateY(-10deg) rotateX(10deg); */
		/* transform: translate3d(-4px, -4px, 0px); */
		/* box-shadow: 16px 16px 1px 0px ${BOX_SHADOW_COLOR}; */
		cursor: pointer;
	}
	/* :active {
		transform: translate3d(10px, 10px, 0px);
		box-shadow: 0px 0px 0px 0px ${BOX_SHADOW_COLOR};
	} */
`;

const HomePage = (props: any) => {
	const auth = useAuth();
	const navigate = useNavigate();
	const location = useLocation();

	// const fragmentContainerRef = useRef(null);

	const [units, setUnits] = useState<number[]>([]);
	// useEffect(() => {
	// 	// Implicit grids or something else would be better
	// 	let w = document.body.scrollWidth;
	// 	let test = 9500 / w;
	// 	let rows: number = Math.floor(test * 2);
	// 	let arr: number[] = [];
	// 	let curr = 10; //+ Math.floor(Math.random() * 10) - 5;
	// 	let dir = 1;
	// 	for (let i = 0; i < rows; i++) {
	// 		if (Math.random() < 0.4 + (dir * curr) / 100) {
	// 			dir *= -1;
	// 		}
	// 		if (curr + test * dir < 100 && curr + test * dir > 0) {
	// 			curr += dir * test;
	// 		} else {
	// 			curr += -1 * dir * test;
	// 		}

	// 		arr.push(curr);
	// 	}
	// 	setUnits(arr);
	// }, []);

	const fragmentContainerRef = useCallback((node: any) => {
		if (node) {
			// let w = node.offsetWidth;
			// let h = node.offsetHeight;
			// let ratio = h / w;
			// // let
			// let cellHeight = 20 * ratio;
			// let cellWidth = 20 * ratio;
			// let rows = Math.floor(h / cellHeight);
			// let columns = Math.floor(w / cellWidth);
			// let units = new Array(rows).fill(0).map((e: number, i: number) => (i / ratio / (rows / ratio)) * 100);
			// console.log(w, h);
			// console.log(cellWidth, cellHeight);
			// console.log(columns, rows);
			// setUnits(units);
			// console.log(units);
			let w = document.body.scrollWidth;
			let test = 9500 / w;
			let rows: number = Math.floor(test * 2);
			let arr: number[] = [];
			let curr = 10; //+ Math.floor(Math.random() * 10) - 5;
			let dir = 1;
			for (let i = 0; i < rows; i++) {
				if (Math.random() < 0.4 + (dir * curr) / 100) {
					dir *= -1;
				}
				if (curr + test * dir < 100 && curr + test * dir > 0) {
					curr += dir * test;
				} else {
					curr += -1 * dir * test;
				}
				arr.push(curr);
			}
			setUnits(arr);
		}
	}, []);

	const iconStyle: CSS.Properties = {
		// fontSize: "40px",
		width: "40px",
		height: "40px",
	};
	const infos = [
		{
			icon: <SmartToyOutlinedIcon sx={iconStyle} />,
			title: "Synthea",
			info: "To achieve realistic data, we utilize Synthea, an open-source tool for generating synthetic patients.",
			link: "https://synthea.mitre.org/",
		},
		{
			icon: <LocalFireDepartmentIcon sx={iconStyle} />,
			title: "FHIR",
			info: "Generated data is represented using the FHIR standard, making it compatible with a range of applications.",
			link: "https://hl7.org/FHIR/",
		},
		{
			icon: <CloudOutlinedIcon sx={iconStyle} />,
			title: "Open DIPS",
			info: "Your synthetic data needs a place to live. Open DIPS provides storage and makes it available for all your applications.",
			link: "https://open.dips.no/",
		},
	];

	const theme = useTheme();
	const notSmall: boolean = useMediaQuery(theme.breakpoints.up("md"));
	return (
		<Container>
			<OverlappingContainer notSmall={notSmall}>
				<WelcomeContainer>
					<Typography variant="h4">Welcome to Synne</Typography>
					<WelcomeText>
						Synne is your best friend when it comes to testing out e-health applications. You need synthetic, but most
						importantly, realistic data, and we got it covered for you. Click the buttons below to start using Synne.
					</WelcomeText>

					<Menu>
						{auth?.authenticated ? (
							<Button
								onClick={() => navigate("/generate")}
								variant="contained"
								color="secondary"
								aria-label="Go to generate page"
							>
								Generate
							</Button>
						) : (
							<Button
								onClick={() => auth?.onLogin(location.pathname)}
								variant="outlined"
								color="secondary"
								aria-label="Login"
							>
								Login
							</Button>
						)}

						<Button
							onClick={() => navigate("/getting-started")}
							variant="outlined"
							color="secondary"
							aria-label="Go to getting started page"
						>
							Getting Started
						</Button>
					</Menu>
				</WelcomeContainer>
				{notSmall && (
					<FragmentContainer ref={fragmentContainerRef}>
						{units.map((e: number, i: number) => (
							<Fragment
								units={e}
								key={i}
								delay={Math.abs(Math.abs((i - units.length / 2) * 60) - (60 * units.length) / 2)}
							></Fragment>
						))}
					</FragmentContainer>
				)}
				{notSmall && <ImageContainer />}
			</OverlappingContainer>
			<Stack direction={{ xs: "column", sm: "row" }} spacing="7vw" justifyContent="space-between">
				<Grid container justifyContent="space-between" rowSpacing={4}>
					{infos.map((e: any, i: number) => (
						<Grid item key={i} md={3.5}>
							<Link href={e.link} target="_blank" underline="none">
								<InfoBox>
									<Stack direction="row" alignItems="flex-start">
										<Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
											<Typography variant="h5" sx={{ fontWeight: "bold" }}>
												{e.title}
											</Typography>
											{e.icon}
										</Stack>
									</Stack>
									<Typography variant="h6">{e.info}</Typography>
								</InfoBox>
							</Link>
						</Grid>
					))}
				</Grid>
			</Stack>
		</Container>
	);
};

export default HomePage;
