import React from "react";
import {
	Typography,
	Stack,
	Box,
	List,
	ListItem,
	ListItemText,
	ListItemAvatar,
} from "@mui/material";
import SickIcon from "@mui/icons-material/Sick";
import styled from "styled-components";
import ResourceJSONAccordion from "./ResourceJSONAccordion";

import { R4 } from "@ahryman40k/ts-fhir-types";
import GeneralInformationDto from "src/dtos/GeneralInformationDto";

const Container = styled.div`
	display: grid;
	grid-row-gap: 2vh;
	grid-template-columns: 100%;
`;

const PersonalDetailsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 2fr;
`;

interface IViewPatient {
	info: GeneralInformationDto;
}
// For viewing general information and the patient resource
const ViewPatient = (props: IViewPatient) => {
	// const patientResource: any = props.bundle.entry!.filter(
	// 	(e: R4.IBundle_Entry) => e?.resource?.resourceType === "Patient"
	// )[0].resource;

	const calculateAge = (birthDate: string) => {
		let today = new Date();
		let birth = new Date(birthDate);
		let age = today.getFullYear() - birth.getFullYear();
		let monthDifference = today.getMonth() - birth.getMonth();
		if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) age--;
		return age;
	};

	const gatherGeneralInformation = (info: GeneralInformationDto) => {
		// if (!bundle.entry) return null;
		// let patient: R4.IPatient = bundle.entry
		// 	.filter((e: R4.IBundle_Entry) => e.resource?.resourceType === "Patient")
		// 	.map((e: R4.IBundle_Entry) => e.resource)[0] as R4.IPatient;

		// const conditions: R4.ICondition[] = bundle?.entry
		// 	?.filter((e: R4.IBundle_Entry) => e?.resource?.resourceType === "Condition")
		// 	.map((e: R4.IBundle_Entry) => e.resource) as R4.ICondition[];

		let generalInfo: any = {};
		generalInfo["gender"] = info.gender;
		generalInfo["birthDate"] = info.birthDate;
		generalInfo["age"] = calculateAge(info.birthDate);
		generalInfo["ssn"] = info.ssn;
		generalInfo["languages"] = info.languages;
		generalInfo["birthPlace"] = info.birthPlace;
		// generalInfo = {
		// 	...generalInfo,
		// 	...patient?.extension?.filter((e: any) => e.hasOwnProperty("valueAddress"))[0].valueAddress,
		// };

		let conditionOccurences: any = {};
		info.conditions.forEach((c: string) => {
			conditionOccurences[c] = conditionOccurences[c] ? conditionOccurences[c] + 1 : 1;
		});
		let sortable = [];
		for (let c in conditionOccurences) sortable.push([c, conditionOccurences[c]]);
		sortable.sort((a, b) => {
			return b[1] - a[1];
		});

		generalInfo["conditionOccurences"] = sortable;

		let disorders: string[] = [];
		info.conditions.forEach((c: string) => {
			if (c.includes("disorder") || c.toLowerCase().includes("diabetes")) {
				disorders.push(c);
			}
		});

		generalInfo["disorders"] = disorders;

		return generalInfo;
	};
	// Birth date:
	// {generalInfo["birthDate"] ? generalInfo["birthDate"] + " (" + generalInfo["age"] + ") " : "unknown"}

	let generalInfo = gatherGeneralInformation(props.info);

	return (
		generalInfo && (
			<Container>
				<Typography variant="h6">General information</Typography>
				<Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between" spacing={{ xs: 1, sm: 2 }}>
					<Box>
						<Typography variant="overline">Personal details</Typography>
						<PersonalDetailsContainer>
							<Typography>Gender: </Typography>
							<Typography>{generalInfo["gender"]}</Typography>
							<Typography>Birth date: </Typography>
							<Typography>
								{generalInfo["birthDate"]
									? `${generalInfo["birthDate"]} (${generalInfo["age"]} year${
											generalInfo["age"] > 1 || generalInfo["age"] == 0 ? "s" : ""
									  } old)`
									: "unknown"}
							</Typography>
							<Typography>Birthplace: </Typography>
							<Typography>{generalInfo["birthPlace"]}</Typography>
							<Typography>SSN: </Typography>
							<Typography>{generalInfo["ssn"]}</Typography>
							<Typography>Languages: </Typography>
							<Typography>{generalInfo["languages"].join(", ")}</Typography>
						</PersonalDetailsContainer>
					</Box>
					<Box>
						<Typography variant="overline">Frequent conditions</Typography>

						{generalInfo.conditionOccurences.length > 0 ? (
							<List disablePadding>
								{generalInfo.conditionOccurences.slice(0, 5).map((e: any) => (
									<ListItem disablePadding key={e[0]}>
										<ListItemAvatar>
											{/* <Avatar> */}
											<SickIcon style={{ color: "grey" }} />
											{/* </Avatar> */}
										</ListItemAvatar>
										<ListItemText
											primary={e[0].slice(0, e[0].indexOf("(") != -1 ? e[0].indexOf("(") : e[0].length)}
											secondary={e[1] + " occurences"}
											primaryTypographyProps={{ style: { margin: 0 } }}
										></ListItemText>
									</ListItem>
								))}
							</List>
						) : (
							<Typography>The patient has no conditions.</Typography>
						)}
					</Box>

					<Box
						sx={{
							width: {
								sm: "33%",
							},
						}}
					>
						<Typography variant="overline">More info</Typography>
						{generalInfo.disorders.length > 0 ? (
							<Typography>
								The patient has the following disorders:{" "}
								{generalInfo.disorders
									.map((e: any) => e.slice(0, e.indexOf("(") != -1 ? e.indexOf("(") : e.length).trimEnd())
									.join(", ")}
							</Typography>
						) : (
							<Typography>The patient has no disorders.</Typography>
						)}
					</Box>
				</Stack>
				{/* {showJSON && <ResourceJSONAccordion summary="Patient JSON" details={patientResource} canCopy canDownload />} */}
			</Container>
		)
	);
};

export default ViewPatient;
