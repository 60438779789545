import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from "reactstrap";
import { useAuth } from "../../AuthProvider";
import AuthenticateComponent from "../authentication/AuthenticateComponent";
import "./NavMenu.css";
import { useLocation } from "react-router-dom";

type PathType = {
	text: string;
	authRequired: boolean;
	path: string;
};

const NavMenu = (props: any) => {
	const auth = useAuth();
	const [collapsed, setCollapsed] = useState(false);

	const toggleNavbar = () => {
		let currCollapsed = collapsed;
		setCollapsed(!currCollapsed);
	};

	let location = useLocation();
	const [currPath, setCurrPath] = useState(location.pathname);

	useEffect(() => {
		setCurrPath(location.pathname);
	}, [location]);

	const paths: PathType[] = [
		{
			text: "Getting Started",
			authRequired: false,
			path: "/getting-started",
		},
		{
			text: "Generate",
			authRequired: true,
			path: "/generate",
		},
		{
			text: "Previous",
			authRequired: true,
			path: "/previous",
		},
	];

	return (
		<header>
			<Navbar
				className="navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-3"
				style={{ margin: "0px 8vw 0px" }}
				light
			>
				<NavbarBrand
					tag={Link}
					to="/"
					style={{
						color: "#00149E",
						fontWeight: "bolder",
						fontSize: "35px",
						fontFamily: "CircularXXWeb-Medium",
						letterSpacing: "1px",
					}}
				>
					Synne
				</NavbarBrand>
				<NavbarToggler onClick={toggleNavbar} className="mr-2" />
				<Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={collapsed} navbar>
					<ul className="navbar-nav flex-grow">
						{paths.map((e: PathType) => {
							let child: any = (
								<NavItem key={e.path}>
									<NavLink
										tag={Link}
										to={e.path}
										style={{
											fontWeight: currPath === e.path ? "bold" : "400",

											fontSize: "1.2em",
											color: "#00149E",
										}}
									>
										{e.text}
									</NavLink>
								</NavItem>
							);
							return e.authRequired ? <AuthenticateComponent key={e.path}>{child}</AuthenticateComponent> : child;
						})}
						{auth ? (
							<NavItem>
								{auth?.authenticated ? (
									//TODO
									<NavLink
										// className="text-dark font-weight-bold"
										onClick={() => auth.onLogout()}
										to=""
										tag={Link}
										style={{ fontSize: "1.2em", color: "#00149E" }}
									>
										{" "}
										{/* tag={Link} className="text-dark" to="/" onClick={updateLoggedIn}> */}
										Log out
									</NavLink>
								) : (
									<NavLink
										// className="text-dark font-weight-bold"
										onClick={() => auth.onLogin(location.pathname)}
										tag={Link}
										to={location}
										style={{ fontSize: "1.2em", color: "#00149E" }}
									>
										{" "}
										{/* tag={Link} className="text-dark" to="/" onClick={updateLoggedIn}> */}
										Log in
									</NavLink>
								)}
							</NavItem>
						) : (
							<></>
						)}
					</ul>
				</Collapse>
			</Navbar>
		</header>
	);
};

export default NavMenu;
