import { Typography } from "@mui/material";
import Rippy from "src/assets/rippy.png";
import styled from "styled-components"; 

const Container = styled.div`
	display: grid;
	justify-content: center;
	grid-row-gap: 10vh;
`;

const Image = styled.img`
	height: 40vh;
	justify-self: center;
`;

const NotFound = () => {
	return (
		<Container>
			<Typography variant="h4">The page you are looking for does not exist</Typography>
			{/* <Image src={Rippy} /> */}
		</Container>
	);
};

export default NotFound;
