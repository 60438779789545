import { Button, Divider, Grid, Link, Stack, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "src/AuthProvider";
import Step1Image from "../assets/getting-started/step1.png";
import Step2Image from "../assets/getting-started/step2.png";
import Step3Image from "../assets/getting-started/step3.png";
import Step4Image from "../assets/getting-started/step4.png";

// TODO: Look more into union types. As of now, buttontext is not required when externalpath is present (it should be)
// TODO: Change name of product
type StepType =
	| {
			title: string;
			description: string;
			internalPath: string;
			buttonText: string;
			authRequiredForPath: boolean;
			authRequiredText: string;
			imagePath: string;
	  }
	| {
			title: string;
			description: string;
			externalPath: string;
			buttonText: string;
			imagePath: string;
	  }
	| {
			title: string;
			description: string;
			imagePath: string;
	  };

const UserGuide = () => {
	const navigate = useNavigate();
	let productName = "Private";

	const steps: StepType[] = [
		// {
		// 	title: `Subscribe to the ${productName} product`,
		// 	description: `Before using Synne, you will need a subscription on the ${productName} product over at Open DIPS. Subscribing to this product grants you access to all the features Synne has to offer. To request a subscription, you will first need a profile at Open DIPS.`,
		// 	// externalPath: `https://open.dips.no/product#product=${productName.toLowerCase()}`,
		// 	externalPath: `https://open.dips.no/products`,
		// 	buttonText: `Subscribe to the ${productName} product`,
		// 	imagePath: Step1Image,
		// },
		{
			title: "Generating data",
			description: `With a ${productName} subscription you are ready to generate your synthetic patient data. You are given multiple options to adjust the characteristics of your patients. Choose some details and click generate. After a bit of waiting, your fresh synthetic data will be delivered to you.`,
			internalPath: "/generate",
			buttonText: "Go to the Generate page",
			authRequiredForPath: true,
			authRequiredText: "Log in before generating patients",
			imagePath: Step2Image,
		},
		{
			title: "Viewing your generated data",
			description:
				"When your data is ready, a list of patients will appear. Each of these can be expanded to view a summary. You can now choose to upload all of them to Open DIPS, or each of them individually. The same goes for downloading the patients. You are now in charge of this data.",
			imagePath: Step3Image,
		},
		{
			title: "Keeping track of previously generated data",
			description:
				"Data uploaded to Open DIPS will be organised into datasets which you can keep track of. You will also be presented with the parameters that you used for generating each dataset. ",
			internalPath: "/previous",
			buttonText: "View previous uploads",
			authRequiredForPath: true,
			authRequiredText: "Log in before viewing previous uploads",
			imagePath: Step4Image,
		},
	];

	const auth = useAuth();
	const location = useLocation();

	return (
		<Grid
			container
			rowSpacing={6}
			justifyContent="space-evenly"
			sx={{
				backgroundColor: "white",
				paddingBottom: "2rem",
			}}
		>
			<Grid item xs={12} md={12} sx={{ paddingLeft: "2rem" }}>
				<Typography variant="h4">User guide</Typography>
			</Grid>
			{steps.map((e: StepType, idx: number) => (
				<React.Fragment key={idx}>
					<Grid item xs={11} md={5}>
						<Stack alignItems={{ xs: "center", md: "flex-end" }} spacing={2} justifyContent="center" height="100%">
							<Typography variant="h5" textAlign={{ sx: "center", md: "end" }}>{`Step ${idx + 1}: ${
								e.title
							}`}</Typography>
							<Typography textAlign={{ sx: "center", md: "end" }}>{e.description}</Typography>
							{"internalPath" in e &&
								(e.authRequiredForPath ? (
									auth?.authenticated ? (
										<Button variant="contained" onClick={() => navigate(e.internalPath)}>
											{e.buttonText}
										</Button>
									) : (
										<Button onClick={() => auth?.onLogin(location.pathname)} variant="outlined">
											{e.authRequiredText}
										</Button>
									)
								) : (
									<Button variant="contained" onClick={() => navigate(e.internalPath)}>
										{e.buttonText}
									</Button>
								))}
							{"externalPath" in e && (
								<Link href={e.externalPath} target="_blank" underline="none">
									<Button variant="contained">{e.buttonText}</Button>
								</Link>
							)}
						</Stack>
					</Grid>

					<Grid container item xs={11} md={5} justifyContent={{ xs: "center", md: "flex-start" }} alignContent="center">
						<div
							style={{
								// backgroundColor: "blue",
								width: "100%",
								display: "grid",
								justifyItems: "center",
								// padding: "20px",
							}}
						>
							<img src={e.imagePath} width="100%" />
							{/* style={{ boxShadow: "10px 10px 0px 0px #00000029" }}  */}
						</div>
					</Grid>
					{idx < steps.length - 1 && (
						<Grid item xs={11} md={11}>
							<Divider />
						</Grid>
					)}
				</React.Fragment>
			))}
		</Grid>
	);
};

export default UserGuide;
