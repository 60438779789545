import { Alert, AlertColor, AlertTitle, Box, Stack, Typography } from "@mui/material";

type AlertExplanation = {
	name: string;
	level: string;
	description: string;
};
const AlertOverview = (props: any) => {
	const alertsExplanations: AlertExplanation[] = [
		{
			name: "Success",
			level: "success",
			description: "The patient was posted without any errors.",
		},
		// TODO include this
		// {
		// 	name: "Info",
		// 	level: "info",
		// 	description: "dunno",
		// },
		{
			name: "Warning",
			level: "warning",
			description:
				"Some parts of the patient data could not be posted, due to incompatibility. Compatible data was however posted.",
		},
		{
			name: "Error",
			level: "error",
			description:
				"The patient could not be posted. The reason could be an internal server error or that services did not respond.",
		},
	];

	return (
		<Box
			sx={{
				backgroundColor: "white",
				padding: "2rem",
			}}
		>
			<Typography variant="h4">Overview of different types of alerts</Typography>
			<Typography variant="h6">
				When posting your data to Open DIPS, you may encounter some alerts that inform you about any obstacles that were
				met. There are {["one", "two", "three", "four"][alertsExplanations.length - 1]} types of alerts, each with
				different type of severity.{" "}
			</Typography>
			<Stack sx={{ marginTop: "2rem", borderRadius: "2rem" }} spacing={2}>
				{alertsExplanations.map((a: AlertExplanation) => (
					<Alert severity={a.level as AlertColor} sx={{ borderRadius: "0.5rem" }}>
						<AlertTitle sx={{ fontWeight: "bold" }}>{a.name}</AlertTitle>
						<Typography>{a.description}</Typography>
					</Alert>
				))}
			</Stack>
		</Box>
	);
};

export default AlertOverview;
