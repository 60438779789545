import { Typography, Box, Grid } from "@mui/material";
import { useAuth } from "../../AuthProvider";

interface IProps {
	children: any;
}
const AuthenticateRoute = (props: IProps) => {
	const auth = useAuth();

	const getMessage = () => {
		const pageName: string | undefined = window.location.href.split("/").at(-1);
		if (pageName) {
			return `Please log in to access the ${pageName.slice(0, 1).toUpperCase() + pageName.slice(1)} page`;
		} else return "Please log in to access this page";
	};

	if (auth?.authenticated) {
		return <>{props.children}</>;
	} else {
		const message = getMessage();
		return (
			<Grid container justifyContent="center">
				<Typography variant="h4">{message}</Typography>
			</Grid>
		);
	}
};

export default AuthenticateRoute;
