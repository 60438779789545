import { TextField } from "@mui/material";
import { formStateType } from "src/pages/GeneratePage";

interface DatasetDetailsInterface {
	name: string;
	showError: boolean;
	updateFormStates: (prop: (keyof formStateType)[], value: string[]) => void;
	updateValidProp: (prop: keyof formStateType, value: boolean) => void;
}

const DatasetDetails = ({ showError, name, updateFormStates, updateValidProp }: DatasetDetailsInterface) => {
	return (
		<TextField
			label="Dataset Name"
			required={true}
			onChange={(e) => {
				updateFormStates(["name"], [e.target.value]);
				let valid = e.target.value !== "";
				updateValidProp("name", valid);
			}}
			error={showError && name == ""}
			helperText={
				showError ? "Dataset name must be set" : "Try to choose a name that describes the generated data well"
			}
			value={name}
			inputProps={{ "aria-label": "Dataset name" }}
		/>
	);
};
export default DatasetDetails;