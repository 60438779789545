import React, { useEffect, useState } from "react";
import {
	TextField,
	Typography,
	Stack,
	Autocomplete,
	Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import demography from "../../assets/statesWithCities.json";
import { formStateType } from "src/pages/GeneratePage";

interface StateAndCityInterface {
	selectedState: string;
	selectedCity: string;

	updateFormStates: (prop: (keyof formStateType)[], value: string[]) => void;
}

export const StateAndCity = ({ selectedState, selectedCity, updateFormStates }: StateAndCityInterface) => {
	const [cities, setCities] = useState<{ label: string; id: number; state: string }[]>([]);
	const [cityId, setCityId] = useState<number>();

	let citiesWithIds: { [key: string]: { label: string; id: number; state: string }[] } = {};
	let stateObjects = Object.entries(demography);

	let index = 0;
	for (let i = 0; i < stateObjects.length; i++) {
		const element = stateObjects[i];
		citiesWithIds[element[0]] = element[1].map((e) => {
			index++;
			return { label: e, id: index, state: element[0] };
		});
	}

	useEffect(() => {
		let newCities: { label: string; id: number }[] = [];

		if (selectedState != "" && selectedState != null) {
			setCities(citiesWithIds[selectedState]);
			// If the selected state has only one city, then automatically select it
			if (citiesWithIds[selectedState].length == 1) {
				updateFormStates(["city"], [citiesWithIds[selectedState][0].label]);
				setCityId(citiesWithIds[selectedState][0].id);
			}
			return;
		}
		setCities(Object.values(citiesWithIds).flat());
	}, [selectedState]);

	return (
		<Stack direction="row">
			<Autocomplete
				isOptionEqualToValue={(option, value) => {
					if (option === value || value === "") {
						return true;
					}
					return false;
				}}
				disablePortal
				options={Object.keys(demography)}
				value={selectedState}
				renderInput={(params) => <TextField {...params} margin={"normal"} label="County" />}
				onChange={(evt: any, value: any) => {
					let curr = value;
					if (curr == null) {
						curr = "";
					}
					updateFormStates(["state", "city"], [curr, ""]);
				}}
				fullWidth={true}
				autoComplete={true}
				// autoSelect={true}
				clearIcon={selectedState.length > 0 && <ClearIcon fontSize="small" />}
			/>

			<Autocomplete
				// label="City"
				isOptionEqualToValue={(option, value) => {
					if (option.id === value.id || value.label === "") {
						return true;
					}
					return false;
				}}
				disablePortal
				id="combo-box-demo"
				options={cities}
				value={{ label: selectedCity, id: cityId, state: selectedState }}
				onChange={(evt: any, value: any) => {
					let curr: string = "";
					if (value != null) {
						curr = value.label.split(" ")[0];
						setCityId(value.id);
					} else {
						setCityId(-1);
					}
					if (selectedState.length == 0) {
						updateFormStates(["state", "city"], [value.state, curr]);
						return;
					}
					updateFormStates(["city"], [curr]);
				}}
				renderInput={(params) => <TextField {...params} margin={"normal"} label="City" />}
				fullWidth={true}
				renderOption={(props, option) => (
					<Box component="li" {...props} key={option.id}>
						{/* <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}> */}
						<Typography>{option.label}</Typography>
						{/* <Typography color="text.secondary">{`(${option.state})`}</Typography>
						</Stack> */}
					</Box>
				)}
				autoComplete={true}
				clearIcon={selectedCity.length > 0 && <ClearIcon fontSize="small" />}
				groupBy={(option) => option.state}
				// autoSelect={true}
			/>
		</Stack>
	);
};

export default StateAndCity;
