import { useAuth } from "../../AuthProvider";

interface IProps {
	children: any;
}
const AuthenticateComponent = (props: IProps) => {
	const auth = useAuth();
	if (auth?.authenticated) {
		return <>{props.children}</>;
	} else {
		return null;
	}
};

export default AuthenticateComponent;
