import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DoneIcon from '@mui/icons-material/Done';
import DownloadIcon from "@mui/icons-material/Download";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from '@mui/icons-material/Info';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	AlertColor,
	CircularProgress,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import GeneralInformationDto from "src/dtos/GeneralInformationDto";
import { useAllResourcesCollapsedStore } from "src/stores/useAllResourcesCollapsedStore";
import styled from "styled-components";
import TooltipIconButton from "../buttons/TooltipIconButton";
import GenderIcon from "./GenderIcon";
import ViewPatient from "./ViewPatient";
import PatientIdCopyable from "../PatientIdCopyable";

const SummaryContainer = styled.div`
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	width: 100%;
`;

interface IGenderAndNameContainer {
	expanded: boolean;
}
const GenderAndNameContainer = styled.div<IGenderAndNameContainer>`
	display: grid;
	grid-auto-flow: column;
	justify-content: left;
	align-items: center;

	transition: 0.2s ease;
	transform-origin: center left;

	/* ${(props) => (props.expanded ? "font-size-adjust: 1.5;" : "")} */
`;

// const PushToRightContainer = styled.div`
// 	justify-self: end;
// 	display: grid;
// 	grid-auto-flow: column;
// 	/* grid-gap: 2vw; */
// `;

const ResourcesContainer = styled.div`
	display: grid;
	grid-template-columns: 100%;
	grid-row-gap: 20px;
`;

const BottomCollapseContainer = styled.div`
	/* display: grid;
	align-items: center;
	grid-template-columns: auto 1fr; */
	text-align: center;
`;

interface IViewBundle {
	info: GeneralInformationDto; // The R4 bundle to process and view
	postBundles: (indices?: number[]) => void; // Method to post bundle to open dips
	handleDownload: (evt: any, index: number) => void;
	posting: boolean; // Boolean indicating whether the bundle is currently being posted
	posted: boolean; // Boolean indicating if the bundle has been posted
	index: number; // Index of all bundles generated
	postingAll: boolean; // Whether all bundles including this one is being posted
	alerts: Array<{
		errorType: string;
		errorText: string;
	}>; // Errors and warnings to display
	disabled: boolean; // Whether the bundle is disabled (cannot be expanded if true)
	patientId: string | null;
}

const ViewBundle = (props: IViewBundle) => {
	const [expanded, setExpanded] = useState(false);
	const [expandedId, setExpandedId] = useState("");
	const [allCollapsedChanger, expandedList, appendExpandedList, removeFromExpandedList] = useAllResourcesCollapsedStore(
		(state) => [state.allCollapsedChanger, state.expandedList, state.appendExpandedList, state.removeFromExpandedList]
	);
	const theme = useTheme();

	useEffect(() => {
		setExpanded(false);
	}, [allCollapsedChanger]);

	const handleExpansion = () => {
		if (expanded) {
			removeFromExpandedList(expandedId);
		} else {
			let newExpandedId = "b" + (expandedList.length === 0 ? 0 : Number(expandedList.slice(-1)[0].slice(-1)[0]) + 1);
			appendExpandedList(newExpandedId);
			setExpandedId(newExpandedId);
		}
		setExpanded(!expanded);
	};

	const alertIcon = () => {
		let child: JSX.Element = <></>;
		switch (props.alerts[0].errorType) {
			case "error":
				child = <ErrorOutlineIcon sx={{ color: theme.palette.error.main }} />;
				break;
			case "warning":
				child = <WarningAmberIcon color={props.alerts[0].errorType as AlertColor} />;
				break;
			case "info":
				child = <InfoIcon color={props.alerts[0].errorType as AlertColor} />;
				break;
			case "success":
				child = <DoneIcon color={props.alerts[0].errorType as AlertColor} />;
				break;

			default:
				child = <></>;
		}
		return <Tooltip title={props.alerts[0].errorText}>{child}</Tooltip>;
	};

	return (
		<Accordion
			expanded={expanded}
			defaultExpanded={false}
			TransitionProps={{ unmountOnExit: true }}
			disabled={props.disabled}
		>
			<AccordionSummary
				expandIcon={
					<Tooltip title={expanded ? "Collapse" : "Expand"}>
						<ExpandMoreIcon />
					</Tooltip>
				}
				onClick={handleExpansion}
			>
				<SummaryContainer>
					<Stack direction="row" alignItems="center" justifyContent="space-between">
						<Stack direction="row" alignItems="center" spacing={2}>
							<GenderAndNameContainer expanded={expanded}>
								{props.alerts && props.alerts.length !== 0 ? alertIcon() : <></>}
								<GenderIcon gender={props.info.gender} style={{ color: "grey" }} />
								<Typography
									sx={{
										textDecoration:
											props.alerts && props.alerts.length !== 0 && props.alerts[0].errorType === "error"
												? "line-through"
												: "",
									}}
								>
									{props.info.name}
								</Typography>
							</GenderAndNameContainer>
							{props.patientId && <PatientIdCopyable patientId={props.patientId} exists={true} />}
						</Stack>
						<div>
							<TooltipIconButton
								onClick={(evt: React.MouseEvent<HTMLButtonElement>) => props.handleDownload(evt, props.index)}
								tooltip="Download patient JSON"
								aria-label="Download patient JSON"
							>
								<DownloadIcon />
							</TooltipIconButton>

							<TooltipIconButton
								onClick={(evt: any) => {
									evt.stopPropagation();
									props.postBundles([props.index]);
								}}
								tooltip={
									props.posted
										? "Patient has been posted"
										: props.posting
										? "Posting patient"
										: "Post patient to Open DIPS"
								}
								disabled={props.posting || props.posted || props.postingAll}
								aria-label="Post patient to Open DIPS"
							>
								{props.posted ? (
									<CloudDoneIcon />
								) : props.posting ? (
									<CircularProgress size={23} />
								) : (
									<CloudUploadIcon />
								)}
							</TooltipIconButton>
						</div>
					</Stack>
				</SummaryContainer>
			</AccordionSummary>
			<AccordionDetails>
				<ResourcesContainer>
					<ViewPatient info={props.info} />
				</ResourcesContainer>
				<BottomCollapseContainer>
					<TooltipIconButton
						tooltip="Collapse"
						onClick={handleExpansion}
						buttonStyle={{ backgroundColor: "transparent" }}
					>
						<ExpandMoreIcon style={{ transform: "rotateZ(180deg)" }} />
					</TooltipIconButton>
				</BottomCollapseContainer>
			</AccordionDetails>
		</Accordion>
	);
};

export default ViewBundle;
