import create from "zustand"


interface AllResourcesCollapsedStore {
    allCollapsedChanger: number,
    incrementAllCollapsedChanger: () => void,
    expandedList: string[],
    appendExpandedList: (id: string) => void,
    removeFromExpandedList: (id: string) => void,
    removeTypesFromExpandedList: (type: string) => void,
    resetExpandedList: () => void

}

// This is currently only used in ViewBundle and ResourceJSONAccordion
export const useAllResourcesCollapsedStore = create<AllResourcesCollapsedStore>((set) => ({
    allCollapsedChanger: 0,
    incrementAllCollapsedChanger: () => set((state) => ({allCollapsedChanger: state.allCollapsedChanger + 1})),
    expandedList: [],
    appendExpandedList: (id: string) => set((state) => ({expandedList: [...state.expandedList, id]})),
    removeFromExpandedList: (id: string) => set((state) => ({expandedList: state.expandedList.filter((e: string) => e != id)})),
    removeTypesFromExpandedList: (type: string) => set((state) => ({expandedList: state.expandedList.filter((e: string) => !e.includes(type))})),
    resetExpandedList: () => set(() => ({expandedList: []}))
}))