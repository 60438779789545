import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { Button, Card, Checkbox, FormControlLabel, Toolbar, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useRef, useState } from "react";
import { useAllResourcesCollapsedStore } from "src/stores/useAllResourcesCollapsedStore";
import styled from "styled-components";
import TooltipIconButton from "../buttons/TooltipIconButton";
import CloudDoneIcon from "@mui/icons-material/CloudDone";

const MenuGrid = styled.div`
	display: grid;
	/* grid-template-columns: 100%; */
	grid-auto-flow: column;
	width: 100%;
`;

const MenuAlternativesContainer = styled.div`
	justify-self: start;
	display: grid;
	grid-auto-flow: column;
	grid-gap: 1vw;
`;

interface IBundlesMenu {
	downloadBundles: any;
	postBundles: (indices?: number[]) => void;
	postedBundles: boolean;
	postingBundles: boolean[];
	postingAllBundles: boolean;
}
const BundlesMenu = (props: IBundlesMenu) => {
	// Collapse all accordions
	const [
		allCollapsedChanger,
		incrementAllCollapsedChanger,
		expandedList,
		removeTypesFromExpandedList,
		resetExpandedList,
	] = useAllResourcesCollapsedStore((state) => [
		state.allCollapsedChanger,
		state.incrementAllCollapsedChanger,
		state.expandedList,
		state.removeTypesFromExpandedList,
		state.resetExpandedList,
	]);

	const collapseButtonOnClick = () => {
		incrementAllCollapsedChanger();
		resetExpandedList();
		// setTimeout(() => {
		// 	window.scrollTo(0, 0);
		// }, 300);
	};

	const theme = useTheme();
	const notSmall: boolean = useMediaQuery(theme.breakpoints.up("sm"));

	const uploadButtonProps = {
		disabled: props.postedBundles || props.postingAllBundles || props.postingBundles.some((e) => e),
		onClick: (evt: any) => props.postBundles(),
		"aria-label": "Post all patients to Open DIPS",
	};

	const downloadButtonProps = {
		"aria-label": "Download all patients",
		onClick: (evt: any) => props.downloadBundles(),
	};

	const collapseButtonProps = {
		onClick: (evt: any) => collapseButtonOnClick(),
		disabled: !expandedList.some((e: string) => e[0] === "b"),
		"aria-label": "Collapse all open patients",
	};
	return (
		<Card>
			<Toolbar>
				<MenuGrid>
					{/* <FormControlLabel
						control={<Checkbox onChange={handleShowJSONChange} checked={showJSON} />}
						label="Show JSONs"
					/> */}

					<MenuAlternativesContainer>
						{/* <TooltipIconButton tooltip="Download" onClick={props.downloadBundles}>
								<DownloadIcon />
							</TooltipIconButton> */}

						{notSmall ? (
							<Button variant="contained" {...downloadButtonProps} startIcon={<DownloadIcon />}>
								Download
							</Button>
						) : (
							<TooltipIconButton {...downloadButtonProps} tooltip="Download">
								<DownloadIcon />
							</TooltipIconButton>
						)}

						{notSmall ? (
							<LoadingButton
								loading={props.postingAllBundles}
								variant="contained"
								{...uploadButtonProps}
								startIcon={props.postedBundles ? <CloudDoneIcon /> : <CloudUploadIcon />}
								loadingPosition="start"
							>
								Post to Open DIPS
							</LoadingButton>
						) : (
							<TooltipIconButton
								tooltip={
									props.postedBundles
										? "All patients have been posted"
										: props.postingAllBundles
										? "Posting"
										: "Post to Open DIPS"
								}
								{...uploadButtonProps}
							>
								{props.postedBundles ? (
									<CloudDoneIcon />
								) : props.postingAllBundles ? (
									<CircularProgress size={23} />
								) : (
									<CloudUploadIcon />
								)}
								{/* {props.postingAllBundles ? <CircularProgress size={23} /> : <CloudUploadIcon />} */}
							</TooltipIconButton>
						)}

						{notSmall ? (
							<Button variant="text" {...collapseButtonProps}>
								Collapse All
							</Button>
						) : (
							<TooltipIconButton tooltip="Collapse All" {...collapseButtonProps}>
								<ExpandCircleDownIcon style={{ transform: "rotateZ(180deg)" }} />
							</TooltipIconButton>
						)}
					</MenuAlternativesContainer>
				</MenuGrid>
			</Toolbar>
		</Card>
	);
};

export default BundlesMenu;
