export interface PostErrorDto {
    bundleIndex?: number;
    // ErrorType: string;
    errorText: string;
}

export default interface PostAlertResponseDto {
    errors: PostErrorDto[];
    warnings: PostErrorDto[];
    infos: PostErrorDto[];
    success: PostErrorDto[];
}

export type alertType = {
    bundleIndex?: number;
    errorType: string;
    errorText: string;
}


export function getHighestAlert(AlertObject: PostAlertResponseDto): (alertType | null) {
    if (AlertObject.errors.length !== 0){
        return {bundleIndex: AlertObject.errors[0].bundleIndex, errorType: "error", errorText:  AlertObject.errors[0].errorText};
    }
    if (AlertObject.warnings.length !== 0){
        return {bundleIndex: AlertObject.warnings[0].bundleIndex, errorType: "warning", errorText:  AlertObject.warnings[0].errorText};
    }
    if (AlertObject.infos.length !== 0){
        return {bundleIndex: AlertObject.infos[0].bundleIndex, errorType: "info", errorText:  AlertObject.infos[0].errorText};
    }
    if (AlertObject.success.length !== 0){
        return {bundleIndex: AlertObject.success[0].bundleIndex, errorType: "success", errorText:  AlertObject.success[0].errorText};
    }
    return null;

}