import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import AuthProvider from "./AuthProvider";
import AuthenticateRoute from "./components/authentication/AuthenticateRoute";
import Layout from "./components/shared/Layout";
import "./custom.css";
import "./assets/fonts/Circular WOFF font/stylesheet.css";
import NotFound from "./components/shared/NotFound";

const App = () => {
	return (
		<AuthProvider>
			<Layout>
				<Routes>
					<Route path="/*" element={<NotFound />} />
					{AppRoutes.map((route, index) => {
						const { element, requiresAuth, ...rest } = route;
						return (
							<Route
								key={index}
								{...rest}
								element={requiresAuth ? <AuthenticateRoute>{element}</AuthenticateRoute> : element}
							/>
						);
					})}
				</Routes>
			</Layout>
		</AuthProvider>
	);
};

export default App;
