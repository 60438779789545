import { Box, ClickAwayListener, Fade, Grid, Link, Popper, Stack, Typography } from "@mui/material";
import CSS from "csstype";
import React, { useState } from "react";
import AlertOverview from "src/components/AlertOverview";
import UserGuide from "src/components/UserGuide";
import styled from "styled-components";
import isOnMobile from "../utils/isOnMobile";

const PopperBox = styled(Box)`
	background-color: white;
	border-radius: 10px;
	width: 16rem;
	padding: 1rem;
`;

const GettingStartedPage = () => {
	const styleForLinksOnBlue: CSS.Properties = { color: "white", fontWeight: "bold", textDecorationColor: "white" };

	const [syntheticAnchor, setSyntheticAnchor] = useState<null | HTMLElement>(null);
	const handleMouseEnter = (evt: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
		setSyntheticAnchor(evt?.currentTarget);
	};

	const handleMouseLeave = (evt: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>) => {
		setSyntheticAnchor(null);
	};

	const handleClickAway = () => {
		setSyntheticAnchor(null);
	};
	const popperOpen = Boolean(syntheticAnchor);
	const popperId = popperOpen ? "synthetic-popper" : undefined;

	return (
		<Stack spacing={4}>
			<Grid sx={{ backgroundColor: "#00149e", padding: "2rem", color: "white" }}>
				<Typography variant="h4" color="white" gutterBottom>
					What is Synne?
				</Typography>

				<Typography mt={2} gutterBottom variant="h6">
					Synne is an application for creating synthetic and realistic patient data to be used with applications working
					towards the DIPS ecosystem. It is tightly integrated with{" "}
					<Link href="https://open.dips.no" target="_blank" style={styleForLinksOnBlue}>
						Open DIPS
					</Link>
					, so that the patient data you generate can easily be transferred to that environment. To generate data, we
					utilize{" "}
					<Link href="https://synthea.mitre.org/" target="_blank" style={styleForLinksOnBlue}>
						Synthea
					</Link>
					, an open-source medical history generator. The patients you create are therefore <b>unique</b>,{" "}
					<b>synthetic</b> and <b>realistic</b>.
				</Typography>

				<Typography variant="h5" mt={2} gutterBottom>
					Who is Synne?
				</Typography>

				<Typography variant="h6">
					The name Synne inspired by the Norwegian adjective{" "}
					<ClickAwayListener onClickAway={handleClickAway}>
						<span onMouseLeave={handleMouseLeave}>
							<i
								style={{ borderBottom: " dashed 2px white", cursor: "help" }}
								onMouseEnter={handleMouseEnter}
								onClick={(evt: any) => {
									if (isOnMobile()) handleMouseEnter(evt);
								}}
							>
								syntetisk
							</i>

							<Popper open={popperOpen} id={popperId} anchorEl={syntheticAnchor} placement="top" transition>
								{({ TransitionProps }) => (
									<Fade {...TransitionProps}>
										<PopperBox>
											<Typography>
												<b>English</b>: <i>synthetic</i>
											</Typography>
											<Typography variant="subtitle2">
												devised, arranged, or fabricated for special situations to imitate or replace usual realities
											</Typography>

											<Link
												underline="none"
												href="https://www.merriam-webster.com/dictionary/synthetic"
												target="_blank"
											>
												<Typography variant="overline">Merriam Webster</Typography>
											</Link>
										</PopperBox>
									</Fade>
								)}
							</Popper>
						</span>
					</ClickAwayListener>
					, as well as being a common name in Norway. We want Synne to be a tool that you find helpful when testing out
					your applications, and it having some personality will make that feeling more authentic.
				</Typography>

				<Typography mt={2} variant="h5" gutterBottom>
					Terms of Service
				</Typography>
				<Typography variant="h6">
					By using Synne, you agree to the{" "}
					<Link href="https://open.dips.no/terms" target={"_blank"} style={styleForLinksOnBlue}>
						Terms of Service specified by Open DIPS.
					</Link>
				</Typography>
			</Grid>
			<UserGuide />
			<AlertOverview />
		</Stack>
	);
};

export default GettingStartedPage;

