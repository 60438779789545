import { TextField } from "@mui/material";
import { formStateType } from "src/pages/GeneratePage";

interface PopulationSizeInterface {
	selectedPopulationSize: string;
	updateFormStates: (prop: (keyof formStateType)[], value: string[]) => void;
}

const MAXPOPULATION = 20;
const MINPOPULATION = 0;

export const PopulationSize = ({ selectedPopulationSize, updateFormStates }: PopulationSizeInterface) => {
	return (
		<>
			<TextField
				label={`Population size (max ${MAXPOPULATION})`}
				onChange={(e) => {
					if (e.target.value === "") {
						updateFormStates(["populationSize"], [e.target.value]);
						return;
					}

					let newValue = Number(e.target.value);
					if (
						!isNaN(newValue) &&
						MINPOPULATION < newValue &&
						MAXPOPULATION >= newValue &&
						(newValue >= 1 || newValue <= 0)
					) {
						const withoutLeading0 = +newValue;
						updateFormStates(["populationSize"], [withoutLeading0.toString()]);
					}
				}}
				margin="normal"
				value={selectedPopulationSize}
				inputProps={{ "aria-label": "Population size" }}
			/>
		</>
	);
};

export default PopulationSize;
